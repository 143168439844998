<template>
    <b-overlay :show="loading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(generalSubmit)" @reset.prevent="reset">
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.gen_info') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row >
                                    <b-col sm="4">
                                        <ValidationProvider name="Organization Type" vid="org_type_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="org_type_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.org_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="dataForm.org_type_id"
                                                :options="orgTypeList"
                                                id="org_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.org_type_id === 1">
                                        <ValidationProvider name="Govt Org" vid="govt_org_id" :rules="data.org_type_id === 1 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="govt_org_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('certify_form.org_name')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.govt_org_id"
                                                    :options="organizationProfileList"
                                                    id="govt_org_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.org_type_id === 1">
                                            <ValidationProvider name="Applicant Office Name" vid="applicant_office_name"  :rules="data.org_type_id === 1 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="applicant_office_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.office_name')}} {{ $t('globalTrans.enn') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    id="applicant_office_name"
                                                    v-model="dataForm.applicant_office_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.org_type_id === 1">
                                        <ValidationProvider name="Applicant Office Name (Bn)" vid="applicant_office_name_bn" :rules="data.org_type_id === 1 ? 'required' : ''">
                                        <b-form-group class="row" label-cols-sm="12" label-for="applicant_office_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.office_name')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.applicant_office_name_bn"
                                                id="applicant_office_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Applicant's Organization Name" vid="applicant_org_name" v-if="data.org_type_id === 2" :rules="data.org_type_id === 2 ? 'required' : ''">
                                        <b-form-group class="row" label-cols-sm="12" label-for="applicant_org_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.applicants_org_name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                id="applicant_org_name"
                                                v-model="dataForm.applicant_org_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Applicant's Organization Name(Bn)" vid="applicant_org_name_bn" v-if="data.org_type_id === 2" :rules="data.org_type_id === 2 ? 'required' : ''">
                                        <b-form-group class="row" label-cols-sm="12" label-for="applicant_org_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.applicants_org_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.applicant_org_name_bn"
                                                id="applicant_org_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.org_inf') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col sm="4">
                                        <ValidationProvider name="Area type" vid="area_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="area_type_id "
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.area_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.area_type_id"
                                                    id="area_type_id"
                                                    :options="getAreaTypeList"
                                                    @change="getAreaTypeData(data.area_type_id)"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Division" vid="division_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="division_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.division_id"
                                                    :options="divisionList"
                                                    id="division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="District" vid="district_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="district_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.district_id"
                                                    :options="districtList"
                                                    id="district_id"
                                                    @input="getOfficeName(dataForm.district_id)"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.area_type_id === 1">
                                        <ValidationProvider name="City Corporation" vid="city_corporation_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="city_corporation_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.city_corporation')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.city_corporation_id"
                                                    :options="cityCorporationList"
                                                    id="city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.area_type_id === 2 || data.area_type_id === 3">
                                        <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="upazilla_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.upazilla_id"
                                                    :options="upazilaList"
                                                    id="upazilla_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.area_type_id === 2">
                                        <ValidationProvider name="Pouroshova" vid="pouroshova_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="pouroshova_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.pouroshova')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.pouroshova_id"
                                                    :options="pauroshobaList"
                                                    id="pouroshova_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.area_type_id === 3">
                                        <ValidationProvider name="Union" vid="union_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="union_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.union')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.union_id"
                                                    :options="unionList"
                                                    id="union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.area_type_id > 0">
                                        <ValidationProvider name="Ward" vid="ward_id" rules="">
                                            <b-form-group class="row" label-cols-sm="12" label-for="ward_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.ward')}}
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.ward_id"
                                                    :options="wardList"
                                                    id="ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Post Office Name" vid="post_office_name">
                                            <b-form-group class="row" label-cols-sm="12" label-for="post_office_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('certify_form.post_office_name')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="dataForm.post_office_name"
                                                    id="post_office_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Village Name" vid="village_name">
                                            <b-form-group class="row" label-cols-sm="12" label-for="village_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.village_name')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="dataForm.village_name"
                                                    id="village_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Holding Number(En)" vid="holding_number">
                                            <b-form-group class="row" label-cols-sm="12" label-for="holding_number" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.holding_number_en')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="dataForm.holding_number"
                                                    id="holding_number"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Holding Number(Bn)" vid="holding_number_bn">
                                            <b-form-group class="row" label-cols-sm="12" label-for="holding_number_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.holding_number_bn')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="dataForm.holding_number_bn"
                                                    id="holding_number_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Phone No" vid="mobile_phone_no" rules="required|numeric|max:11|min:11">
                                            <b-form-group class="row" label-cols-sm="12" label-for="mobile_phone_no" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.mobile_phone_no')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    type="number"
                                                    v-model="dataForm.mobile_phone_no"
                                                    id="mobile_phone_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Email Address" vid="email_address">
                                            <b-form-group class="row" label-cols-sm="12" label-for="email_address" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.email_address')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="dataForm.email_address"
                                                    id="email_address"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Dealer Registration Number" vid="dealer_registration_number" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="dealer_registration_number" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.dealer_registration_number')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="number"
                                                    v-model="dataForm.dealer_registration_number"
                                                    id="dealer_registration_number"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Dealer Registration Date" vid="dealer_registration_date" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="dealer_registration_date" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.dealer_registration_date')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                class="date-picker"
                                                v-model="dataForm.dealer_registration_date"
                                                id="dealer_registration_date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('external_research.office_information') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row >
                                    <b-col sm="4">
                                        <ValidationProvider name="Office Type" vid="office_type_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="office_type_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('external_research.office_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="dataForm.office_type_id"
                                                :options="officeTypeList"
                                                id="office_type_id"
                                                disabled
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Office" vid="office_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="office_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="dataForm.office_id"
                                                :options="officeList"
                                                id="office_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            <template v-slot:first>
                                                <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.apps_address') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col md="12">
                                        <ValidationProvider>
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="remarks"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{$t('certify_form.same_as_org_address')}}
                                                </template>
                                                <input type="checkbox"
                                                    name="item"
                                                    class="mt-2"
                                                    id="checkbox"
                                                    @change="selectCheckbox(item)"
                                                    :checked="same_as_call === 1 ? true : false"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                > {{ $t('globalTrans.yes') }}
                                                <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">Please select one</b-form-invalid-feedback>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Applicant's Name(En)" vid="applicant_name" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="applicant_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.applicant_name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.applicant_name"
                                                id="applicant_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Applicant's Name(Bn)" vid="applicant_name_bn" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="applicant_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.applicant_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.applicant_name_bn"
                                                id="applicant_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Area type" vid="a_area_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="a_area_type_id "
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.area_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_area_type_id"
                                                    id="a_area_type_id"
                                                    :options="getAreaTypeList"
                                                    @change="agetAreaTypeData(data.a_area_type_id)"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Division" vid="a_division_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="a_division_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_division_id"
                                                    :options="adivisionList"
                                                    id="a_division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="District" vid="a_district_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="a_district_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_district_id"
                                                    :options="adistrictList"
                                                    id="a_district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.a_area_type_id === 1">
                                        <ValidationProvider name="City Corporation" vid="a_city_corporation_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="a_city_corporation_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.city_corporation')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_city_corporation_id"
                                                    :options="acityCorporationList"
                                                    id="a_city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.a_area_type_id === 2 || data.a_area_type_id === 3">
                                        <ValidationProvider name="Upazilla" vid="a_upazilla_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="a_upazilla_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.upazila')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_upazilla_id"
                                                    :options="aupazilaList"
                                                    id="a_upazilla_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.a_area_type_id === 2">
                                        <ValidationProvider name="Pouroshova" vid="a_pouroshova_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="a_pouroshova_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.pouroshova')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_pouroshova_id"
                                                    :options="apauroshobaList"
                                                    id="a_upazilla_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.a_area_type_id === 3">
                                        <ValidationProvider name="Union" vid="a_union_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="a_union_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                   {{$t('globalTrans.union')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_union_id"
                                                    :options="aunionList"
                                                    id="a_union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="data.a_area_type_id > 0">
                                        <ValidationProvider name="Ward" vid="a_ward_id" rules="">
                                            <b-form-group class="row" label-cols-sm="12" label-for="a_ward_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('globalTrans.ward')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="dataForm.a_ward_id"
                                                    :options="awardList"
                                                    id="a_ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Post Office Name" vid="a_post_office_name">
                                        <b-form-group class="row" label-cols-sm="12" label-for="a_post_office_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.post_office_name')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.a_post_office_name"
                                                id="a_post_office_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Village Name" vid="a_village_name">
                                        <b-form-group class="row" label-cols-sm="12" label-for="a_village_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.village_name')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.a_village_name"
                                                id="a_village_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Holding Number(En)" vid="a_holding_number">
                                        <b-form-group class="row" label-cols-sm="12" label-for="a_holding_number" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.holding_number_en')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.a_holding_number"
                                                id="a_holding_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Holding Number(Bn)" vid="a_holding_number_bn">
                                        <b-form-group class="row" label-cols-sm="12" label-for="a_holding_number_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.holding_number_bn')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.a_holding_number_bn"
                                                id="a_holding_number_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Phone No" vid="a_mobile_phone_no" rules="required|numeric|max:11|min:11">
                                        <b-form-group class="row" label-cols-sm="12" label-for="a_mobile_phone_no" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.mobile_phone_no')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                type="number"
                                                v-model="dataForm.a_mobile_phone_no"
                                                id="a_mobile_phone_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Email Address" vid="a_email_address">
                                        <b-form-group class="row" label-cols-sm="12" label-for="a_email_address" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.email_address')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="dataForm.a_email_address"
                                                id="a_email_address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Signature" vid="signature" ref="signature" required>
                                        <b-form-group class="row" label-cols-sm="12" label-for="signature" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{ $t('admission_form.signature') }} <span class="text-danger">{{ data.id ? '' : '*' }}</span>
                                            <!-- <v-slot v-if="data.signature !== null">
                                                <a :href="baseUrl + 'download-attachment?file=uploads/general-information/attachment/original/' + data.signature" title="Evaluation Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                            </v-slot> -->
                                            </template>
                                            <b-form-file
                                                id="signature"
                                                accept="image/*"
                                                :placeholder="data.signature ? data.signature : 'Choose a file...'"
                                                @change="onSignatureChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-file>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </b-overlay>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeGeneralInfo } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: ['data', 'organization_address', 'applicant_address'],
  data () {
    return {
        dataForm: {},
        districtList: [],
        adistrictList: [],
        cityCorporationList: [],
        acityCorporationList: [],
        wardList: [],
        awardList: [],
        upazilaList: [],
        aupazilaList: [],
        unionList: [],
        aunionList: [],
        officeList: [],
        pauroshobaList: [],
        apauroshobaList: [],
        loading: false,
        tab_position: this.$route.query.tab_position,
        same_as_call: 0,
        tmpSignature: [],
        maritalStatus: [
            { value: 1, text: this.$t('certify_form.single') },
            { value: 2, text: this.$t('certify_form.married') }
        ],
        errors: [],
        seedVarietyList: [],
        options: [
            {
                value: 1,
                text: 'Yes'
            }
        ],
        item: []
    }
  },
  created () {
    // this.getOfficeType()
    this.loading = false
    this.dataForm = this.data
    this.sameAsCall()
    if (this.$route.query.id) {
        this.getOfficeName(this.dataForm.district_id)
        this.dataForm.office_id = this.organization_address.office_id
        this.dataForm.office_type_id = this.organization_address.office_type_id
    }
  },
  watch: {
        'dataForm.area_type_id': function (newVal, oldVal) {
            this.sameAsCall()
        },
        'dataForm.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'dataForm.a_division_id': function (newVal, oldVal) {
            this.adistrictList = this.agetDistrictList(newVal)
        },
        'dataForm.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'dataForm.a_district_id': function (newVal, oldVal) {
            this.acityCorporationList = this.agetCityCorporationList(newVal)
            this.aupazilaList = this.agetUpazilaList(newVal)
        },
        'dataForm.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPouroList(newVal)
        },
        'dataForm.a_upazilla_id': function (newVal, oldVal) {
            this.aunionList = this.agetUnionList(newVal)
            this.apauroshobaList = this.agetPouroList(newVal)
        },
        'dataForm.city_corporation_id': function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.wardList = this.getWardListByCityCorportaion(newVal)
            }
        },
        'dataForm.pauroshoba_id': function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.wardList = this.getWardListByPauroshoba(newVal)
            }
        },
        'dataForm.union_id': function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.wardList = this.getWardListByUnion(newVal)
            }
        },
        'dataForm.a_city_corporation_id': function (newVal, oldVal) {
             if (newVal && newVal !== oldVal) {
                this.awardList = this.agetWardListByCityCorportaion(newVal)
             }
        },
        'dataForm.a_pauroshoba_id': function (newVal, oldVal) {
             if (newVal && newVal !== oldVal) {
                this.awardList = this.agetWardListByPauroshoba(newVal)
             }
        },
        'dataForm.a_union_id': function (newVal, oldVal) {
             if (newVal && newVal !== oldVal) {
                this.awardList = this.agetWardListByUnion(newVal)
             }
        }
        // currentLocale: function () {
        //     const newVal = 0
        //     this.districtList = this.getDistrictList(newVal)
        //     this.adistrictList = this.agetDistrictList(newVal)
        //     this.cityCorporationList = this.getCityCorporationList(newVal)
        //     this.upazilaList = this.getUpazilaList(newVal)
        //     this.acityCorporationList = this.agetCityCorporationList(newVal)
        //     this.aupazilaList = this.agetUpazilaList(newVal)
        //     this.unionList = this.getUnionList(newVal)
        //     this.pauroshobaList = this.getPouroList(newVal)
        //     this.aunionList = this.agetUnionList(newVal)
        //     this.apauroshobaList = this.agetPouroList(newVal)
        //     this.wardList = this.getWardListByCityCorportaion(newVal)
        //     this.wardList = this.getWardListByPauroshoba(newVal)
        //     this.wardList = this.getWardListByUnion(newVal)
        //     this.awardList = this.agetWardListByCityCorportaion(newVal)
        //     this.awardList = this.agetWardListByPauroshoba(newVal)
        //     this.awardList = this.agetWardListByUnion(newVal)
        // }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  computed: {
    // currentLocale () {
    //     return this.$i18n.locale
    // },
    getAreaTypeList: function () {
        const objectData = this.$store.state.commonObj.wardTypeList
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
            } else {
                return { value: obj.id, text: obj.name }
            }
        })
    },
    officeTypeList: function () {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.org_id === 14)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    divisionList: function () {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    adivisionList: function () {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    orgTypeList: function () {
        const objectData = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.orgTypeList
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    organizationProfileList: function () {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    }
  },
  methods: {
    sameAsCall () {
            if (this.data.a_area_type_id !== '' && this.data.a_area_type_id === this.data.area_type_id && this.data.a_division_id === this.data.division_id && this.data.a_district_id === this.data.district_id && this.data.a_city_corporation_id === this.data.city_corporation_id && this.data.a_upazilla_id === this.data.upazilla_id && this.data.a_pouroshova_id === this.data.pouroshova_id && this.data.a_union_id === this.data.union_id && this.data.a_ward_id === this.data.ward_id && this.data.a_post_office_name === this.data.post_office_name && this.data.a_village_name === this.data.village_name && this.data.a_holding_number === this.data.holding_number && this.data.a_holding_number_bn === this.data.holding_number_bn && this.data.a_mobile_phone_no === this.data.mobile_phone_no && this.data.a_email_address === this.data.email_address) {
                this.same_as_call = 1
            } else {
                this.same_as_call = 0
            }
    },
    selectCheckbox (item) {
            this.data.a_area_type_id = this.data.area_type_id
            this.data.a_division_id = this.data.division_id
            this.data.a_district_id = this.data.district_id
            this.data.a_city_corporation_id = this.data.city_corporation_id
            this.data.a_upazilla_id = this.data.upazilla_id
            this.data.a_pouroshova_id = this.data.pouroshova_id
            this.data.a_union_id = this.data.union_id
            this.data.a_ward_id = this.data.ward_id
            this.data.a_post_office_name = this.data.post_office_name
            this.data.a_village_name = this.data.village_name
            this.data.a_holding_number = this.data.holding_number
            this.data.a_holding_number_bn = this.data.holding_number_bn
            this.data.a_mobile_phone_no = this.data.mobile_phone_no
            this.data.a_email_address = this.data.email_address
    },
    async generalSubmit (isNext = 1) {
     const validationResult = await this.$refs.form.validate()
     if (!validationResult) return false

      this.loading = true
      let mainForm = {}
      if (!isNext) {
          this.data.draft = isNext
      }

      mainForm = { ...this.dataForm, user_id: this.$store.state.Auth.authUser.id }

      var formData = new FormData()
      Object.keys(mainForm).map(key => {
        if (key === 'signature') {
            if (this.tmpSignature.name) {
                formData.append(key, this.tmpSignature)
            } else {
                formData.append(key, this.dataForm[key])
            }
        } else {
          formData.append(key, mainForm[key])
        }
      })

      let result = null
      result = await RestApi.postData(agriResearchServiceBaseUrl, storeGeneralInfo, formData)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.loading = false
        return result
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    async onSignatureChange (e) {
      const { valid } = await this.$refs.signature.validate(e)
      if (!valid) return false

      this.tmpSignature = e.target.files[0]
      this.sig_src = URL.createObjectURL(e.target.files[0])
    },
    getDistrictList (divisionId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0 && item.division_id === parseInt(divisionId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetDistrictList (divisionId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0 && item.division_id === parseInt(divisionId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getUpazilaList (districtId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === parseInt(districtId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetUpazilaList (districtId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === parseInt(districtId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getCityCorporationList (districtId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === parseInt(districtId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetCityCorporationList (districtId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === parseInt(districtId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getPouroList (upazilaId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.status === 0 && item.upazilla_id === parseInt(upazilaId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetPouroList (upazilaId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.status === 0 && item.upazilla_id === parseInt(upazilaId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getUnionList (upazilaId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0 && item.upazilla_id === parseInt(upazilaId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetUnionList (upazilaId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0 && item.upazilla_id === parseInt(upazilaId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getWardListByCityCorportaion (cityCorpId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.city_corporation_id === parseInt(cityCorpId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getWardListByPauroshoba (paroshobaId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.pauroshoba_id === parseInt(paroshobaId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getWardListByUnion (unionId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.unionId === parseInt(unionId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetWardListByCityCorportaion (cityCorpId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.city_corporation_id === parseInt(cityCorpId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetWardListByPauroshoba (paroshobaId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.pauroshoba_id === parseInt(paroshobaId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    agetWardListByUnion (unionId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.unionId === parseInt(unionId))
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getOfficeName (id) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.district_id === parseInt(id)).filter(item => item.org_id === 14)
        this.officeList = objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    }
  }
}
</script>
<style scoped>
 .form-control {
  padding: 0.375rem 0.75rem
 }
</style>
