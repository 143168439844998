<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-card no-body v-if="isChecked" class="px-3 mb-4">
          <profile-status-msg :preConditionLoader="panelObj.researchFarmerLoader" :profileStatus="panelObj.profileStatus" :profile-url="'/research-farmer/profile-update'" :inForm="true"/>
          <form-wizard shape="circle" color="#005B5B" title="" subtitle="" :start-index.sync="activeIndex">
            <tab-content class="text-small" :title="$t('certify_form.gen_info')" icon="ri-home-4-line" :before-change="tabSubmitGeneral">
                <General :data="item" :organization_address="item.organization_address" :applicant_address="item.applicant_address" ref="social"/>
            </tab-content>
            <tab-content class="text-small" :title="$t('certify_form.field_seed_info')" icon="ri-home-4-line" :before-change="tabSubmitSeedInfo">
                <FieldAndSeedInfo :generalId="generalId" :govtId="item.govt_org_id" :seed_source="item.seed_source" :plotInfo="item.before_showing_planting" :plot_location="item.plot_location" ref="field"/>
            </tab-content>
            <tab-content class="text-small" :title="$t('certify_form.doc_attach')" icon="ri-home-4-line" :before-change="tabSubmitDocumentAttach">
                <document-attachment :generalId="generalId" :document="item.document" ref="doc"/>
            </tab-content>
            <tab-content class="text-small" :title="$t('certify_form.payment')" icon="ri-home-4-line" :before-change="tabSubmitPayment">
                <Payment :generalId="generalId" :payment="item.payment" ref="payment" :sys_check="sys_check"/>
            </tab-content>
            <tab-content class="text-small" :title="$t('certify_form.after_sowing_planting')" icon="ri-home-4-line" :before-change="tabSubmitAfterSowing">
                <AfterSowingPlanting :fiscalYear="item.before_showing_planting.production_year" :generalId="generalId" :basicSeed="basicSeed" :data="item.showingplanting" :seed_source="item.seed_source" :plotInfo="item.before_showing_planting" :plot_location="item.plot_location" ref="sowing" :app_status="app_status" :sys_check="sys_check"/>
            </tab-content>
            <tab-content class="text-small" :title="$t('certify_form.after_flowers_come')" icon="ri-home-4-line" :before-change="tabSubmitAfterFlowersCome">
                <AfterFlowersCome :fiscalYear="item.before_showing_planting.production_year" :generalId="generalId" :basicSeed="basicSeed" :data="item.flowers" :seed_source="item.seed_source" :plotInfo="item.before_showing_planting" :plot_location="item.plot_location" ref="flowers" :app_status="app_status" :sys_check="sys_check"/>
            </tab-content>
            <tab-content class="text-small" :title="$t('certify_form.after_harvesting')" icon="ri-home-4-line" :before-change="tabSubmitAfterHarvesting">
                <AfterHarvesting :govtId="item.govt_org_id" :areaName="item.before_showing_planting.seed_producer_name" :fiscalYear="item.before_showing_planting.production_year" :generalId="generalId" :harvestData="item.harvesting" :basicSeed="basicSeed" :seed_source="item.seed_source" ref="harvest"/>
            </tab-content>
            <button slot="finish" class="wizard-btn-custom" style="background-color: rgb(0, 91, 91); border-color: rgb(0, 91, 91); color: white;" @click="addForm">Submit</button>
           <template slot="footer" scope="props">
              <div class=wizard-footer-left>
                  <wizard-button class="btn btn-secondary rounded" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0 && !props.isLastStep" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
              </div>
              <slot v-if="item.position < 4">
                <div class="wizard-footer-right">
                  <wizard-button
                      type="submit"
                      v-if="!props.isLastStep"
                      class="btn btn-primary ml-1 mr-1 rounded"
                      title="Create Draft and Save!"
                      :disabled="sys_check > 0 ? true : false"
                      @click.native="props.nextTab(), isNext = true"
                  >
                      {{$t('certify_form.draft_and_save')}}
                  </wizard-button>
                  <wizard-button
                    type="submit"
                    v-else
                    class="btn btn-primary ml-1 mr-1 rounded"
                    title="Submit!"
                    @click.native="props.nextTab(), isNext = true"
                  >
                      {{$t('certify_form.submit')}}
                  </wizard-button>
                  <wizard-button
                      type="submit"
                      v-if="!props.isLastStep"
                      @click.native="props.nextTab(), isNext = true"
                      class="btn btn-danger ml-1 mr-1 rounded"
                      title="Save and go to next!"
                      :style="props.fillButtonStyle"
                      :disabled="sys_check > 0 ? true : false"
                  >{{$t('certify_form.save_and_next')}}</wizard-button>
                  <wizard-button v-else
                      @click.native="isNext = true, tabSubmitAfterHarvesting()"
                      slot="finish"
                      type="submit"
                      class="btn btn-danger ml-1 mr-1 rounded"
                      title="Save and go to next!"
                      :style="props.fillButtonStyle">
                      {{$t('certify_form.exit')}}
                  </wizard-button>
                </div>
              </slot>
              <slot v-else-if="parseInt(activeIndex) < item.position">
                <div class="wizard-footer-right">
                  <wizard-button
                      type="submit"
                      v-if="!props.isLastStep"
                      class="btn btn-primary ml-1 mr-1 rounded"
                      title="Create Draft and Save!"
                      @click.native="props.nextTab(), isNext = true"
                      disabled
                  >
                      {{$t('certify_form.draft_and_save')}}
                  </wizard-button>
                  <wizard-button
                      type="submit"
                      v-else
                      class="btn btn-primary ml-1 mr-1 rounded"
                      title="Submit!"
                      @click.native="props.nextTab(), isNext = true"
                      disabled
                  >
                      {{$t('certify_form.submit')}}
                  </wizard-button>
                  <wizard-button
                      type="submit"
                      v-if="!props.isLastStep"
                      @click.native="props.nextTab(), isNext = true"
                      class="btn btn-danger ml-1 mr-1 rounded"
                      title="Save and go to next!"
                      :style="props.fillButtonStyle"
                      disabled
                  >{{$t('certify_form.save_and_next')}}</wizard-button>
                  <wizard-button v-else
                      @click.native="isNext = true, tabSubmitAfterHarvesting()"
                      slot="finish"
                      type="submit"
                      class="btn btn-danger ml-1 mr-1 rounded"
                      title="Save and go to next!"
                      disabled
                      :style="props.fillButtonStyle">
                      {{$t('certify_form.exit')}}
                  </wizard-button>
                </div>
              </slot>
              <slot v-else>
                 <div class="wizard-footer-right">
                   <wizard-button
                      type="submit"
                      v-if="!props.isLastStep"
                      @click.native="props.nextTab(), isNext = true"
                      class="btn btn-danger ml-1 mr-1 rounded"
                      title="Save and go to next!"
                      :style="props.fillButtonStyle"
                      :disabled="sys_check > 0 ? true : false"
                  >{{$t('certify_form.save_and_next')}}</wizard-button>
                  <wizard-button v-else
                      @click.native="isNext = true, gotToList()"
                      slot="finish"
                      type="button"
                      class="btn btn-danger ml-1 mr-1 rounded"
                      title="Exit"
                      :style="props.fillButtonStyle">
                      {{$t('certify_form.exit')}}
                  </wizard-button>
                  <wizard-button
                      type="submit"
                      v-if="!props.isLastStep"
                      class="btn btn-primary ml-1 mr-1 rounded"
                      title="Create Draft and Save!"
                      :disabled="sys_check > 0 ? true : false"
                      @click.native="props.nextTab(), isNext = true"
                  >
                      {{$t('certify_form.draft_and_save')}}
                  </wizard-button>
                  <wizard-button
                    type="submit"
                    v-else
                    class="btn btn-primary ml-1 mr-1 rounded"
                    title="Submit!"
                    @click.native="props.nextTab(), isNext = true"
                  >
                      {{$t('certify_form.submit')}}
                  </wizard-button>
                </div>
              </slot>
           </template>
          </form-wizard>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getApplicationData } from '../../api/routes'
import General from './General.vue'
import FieldAndSeedInfo from './FieldAndSeedInfo.vue'
import DocumentAttachment from './DocumentAttachment.vue'
import Payment from './Payment.vue'
import AfterSowingPlanting from './AfterSowingPlanting.vue'
import AfterFlowersCome from './AfterFlowersCome.vue'
import AfterHarvesting from './AfterHarvesting.vue'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
import { BIconEmojiNeutralFill, BIconNutFill } from 'bootstrap-vue'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { EventBus } from '@/EventBusLayout'
// import toggleStatus from '@/Utils/toggle-status'
// import { caseHistoryDetailsApi } from '../../api/routes'

export default {
  components: {
    General,
    FieldAndSeedInfo,
    DocumentAttachment,
    Payment,
    AfterSowingPlanting,
    AfterFlowersCome,
    AfterHarvesting,
    FormWizard,
    TabContent,
    WizardButton,
    ProfileStatusMsg
  },
  data () {
    return {
      preconditionLoader: false,
      profileStatus: 4,
      loadItem: false,
      loadItem1: false,
      loadItem2: false,
      loadItem3: false,
      loadItem4: false,
      loadItem5: true,
      loadItem6: false,
      isDisabled: false,
      app_status: 1,
      sys_check: 0,
      item: {
        id: 0,
        general_id: 0,
        applicant_org_name: '',
        applicant_org_name_bn: '',
        applicant_office_name: '',
        applicant_office_name_bn: '',
        org_type_id: '',
        area_type_id: '',
        division_id: '',
        district_id: '',
        office_id: '',
        office_type_id: 113,
        city_corporation_id: '',
        upazilla_id: '',
        pouroshova_id: '',
        union_id: '',
        ward_id: '',
        post_office_name: '',
        village_name: '',
        holding_number: '',
        holding_number_bn: '',
        mobile_phone_no: '',
        email_address: '',
        dealer_registration_number: '',
        dealer_registration_date: '',
        applicant_name: '',
        applicant_name_bn: '',
        a_area_type_id: '',
        a_division_id: '',
        a_district_id: '',
        a_city_corporation_id: '',
        a_upazilla_id: '',
        a_pouroshova_id: '',
        a_union_id: '',
        a_ward_id: '',
        a_post_office_name: '',
        a_village_name: '',
        a_holding_number: '',
        a_holding_number_bn: '',
        a_mobile_phone_no: '',
        a_email_address: '',
        signature: '',
        draft: 1,
        position: 0,
        organization_address: {},
        applicant_address: {},
        // tab_position: {},
        flowers: {
          user_id: this.$store.state.Auth.authUser.id,
          fiscal_year_id: '',
          seed_producer_name: '',
          seed_producer_name_bn: '',
          block_sub_area: '',
          block_sub_area_bn: '',
          gps_location: '',
          latitude: 23,
          longitude: 90,
          production_season_id: '',
          seed_type_id: '',
          seed_name_id: '',
          seed_variety_id: '',
          seed_class_id: '',
          seed_source_name: '',
          seed_source_name_bn: '',
          seed_lot_no: '',
          producer_name: '',
          producer_name_bn: '',
          producer_address: '',
          producer_address_bn: '',
          plot_number: '',
          seed_production_area: '',
          unit_id: '',
          possible_seed_receipt: '',
          possible_date_harvesting: '',
          haulm_pulling_potato_date: '',
          draft: 1,
          general_info_id: ''
        },
        showingplanting: {
          user_id: this.$store.state.Auth.authUser.id,
          fiscal_year_id: '',
          seed_producer_name: '',
          seed_producer_name_bn: '',
          block_sub_area: '',
          block_sub_area_bn: '',
          gps_location: '',
          latitude: 23,
          longitude: 90,
          production_season_id: '',
          seed_type_id: '',
          seed_name_id: '',
          seed_variety_id: '',
          seed_class_id: '',
          seed_source_name: '',
          seed_source_name_bn: '',
          seed_lot_no: '',
          producer_name: '',
          producer_name_bn: '',
          producer_address: '',
          producer_address_bn: '',
          plot_number: '',
          seed_production_area: '',
          unit_id: '',
          draft: 1,
          planting_date: '',
          general_info_id: ''
        },
        plot_location: {
          scheme_id: 0,
          mouja_scheme_name_bn: '',
          mouja_scheme_name: '',
          total_land: 0,
          plot_details: [
              {
                  plot_number: null,
                  amount_of_land: '',
                  unit_id: '',
                  seed_variety_id: '',
                  date_of_harvest: '',
                  gps_location: '',
                  latitude: 23.8103,
                  longitude: 90.4125,
                  draft: '',
                  general_info_id: ''
              }
          ]
        },
        before_showing_planting: {
            user_id: this.$store.state.Auth.authUser.id,
            mouja_scheme_name: '',
            mouja_scheme_name_bn: '',
            draft: '',
            production_year: '',
            seed_producer_name: '',
            b_seed_producer_name: '',
            seed_producer_name_bn: '',
            block_sub_area: '',
            block_sub_area_bn: '',
            gps_location: '',
            b_latitude: 23.8103,
            b_longitude: 90.4125,
            production_season_id: '',
            seed_name: '',
            seed_variety_name: '',
            seed_class_name: '',
            seed_production_area: '',
            b_certificate_issue_date: '',
            b_unit_id: '',
            amount_seed_production: '',
            general_info_id: ''
        },
        seed_source: {
          user_id: this.$store.state.Auth.authUser.id,
          seed_certification_tag_number: null,
          seed_certification_tag_number_bn: null,
          seed_type_id: '',
          seed_name_id: '',
          seed_name_others: '',
          seed_variety_id: '',
          seed_variety_others: '',
          seed_class_id: '',
          lot_number: '',
          validity_period: '',
          certificate_issue_date: '',
          net_weight_seed: '',
          unit_id: '',
          seed_producer_name: '',
          seed_producer_name_bn: '',
          seed_producer_address: '',
          seed_producer_address_bn: '',
          upload_photo: '',
          class_of_seeds: '',
          sowing_planting: '',
          s_certificate_issue_date: '',
          draft: '',
          general_info_id: ''
        },
        document: {
          dealer_reg_certificate: '',
          seed_purchase_receipt: '',
          source_tag_doc: '',
          mouja_map: ''
        },
        payment: {
          draft: 1,
          payment_status: 1,
          treasury_challan_code: '',
          treasury_deposited_amount: '',
          treasury_challan_number: '',
          treasury_challan_date: '',
          treasury_challan_file: '',
          bank_id: '',
          branch_id: '',
          ekpay_deposited_amount: '',
          ekpay_transaction_number: '',
          ekpay_transaction_date: ''
        },
        harvesting: {
          user_id: this.$store.state.Auth.authUser.id,
          seed_pro_center_name: '',
          seed_pro_center_name_bn: '',
          seed_storage_place: '',
          seed_storage_place_bn: '',
          seed_collection_year: '',
          produc_area_space_name: '',
          produ_area_space_name_bn: '',
          produc_area_space_address: '',
          produc_area_space_address_bn: '',
          seed_name: '',
          seed_variety_name: '',
          seed_class_name: '',
          lot_no_provided: '',
          seed_amount_in_lot: '',
          center_id: 0,
          lot_unit_measurement: 0,
          unit_id: 0,
          amount_seeds_per_certification_tag: '',
          packet_id: '',
          total_demand_certification_work: '',
          general_info_id: this.generalId
        }
      },
      basicSeed: {
        seed_type_id: '',
        seed_name_id: '',
        seed_variety_id: '',
        seed_class_id: ''
      },
      generalId: '',
      activeIndex: null,
      saveIndex: null,
      disableNextButton: false,
      isChecked: false,
      itemData: '',
      fieldSeedInfo: '',
      lang: 'en'
    }
  },
  computed: {
    panelObj () {
      return this.$store.state.ExternalUserIrrigation.panelObj
    }
  },
  mounted () {
    core.index()
    this.setLang()
  },
  created () {
    if (this.$route.query.id || this.generalId) {
      this.loadData()
      this.generalId = this.$route.query.id
    } else {
      EventBus.$emit('RESEARCH_FARMER')
      this.isChecked = true
      this.activeIndex = 0
    }
  },
  methods: {
    // checkResearchFarmer () {
    //   this.preconditionLoader = true
    //   RestApi.getData(agriResearchServiceBaseUrl, '/research-panel/profile', { user_id: this.$store.state.Auth.authUser.user_id }).then(response => {
    //     if (response.success) {
    //       this.profileStatus = response.data.status === 1 ? 4 : 0
    //     } else {
    //       this.profileStatus = 0
    //     }
    //     this.preconditionLoader = false
    //   })
    // },
    getFiscalYear () {
        const year = new Date().getFullYear()
        const d = new Date()
        const month = d.getMonth()
        if (month > 6) {
            this.item.before_showing_planting.production_year = year + '-' + (parseInt(year) + 1)
        } else {
            this.item.before_showing_planting.production_year = (parseInt(year) - 1) + '-' + year
        }
    },
    gotToList () {
      this.$router.push('/research-farmer/seed-certification/field-application-list')
    },
    loadData () {
      RestApi.getData(agriResearchServiceBaseUrl, getApplicationData + '/' + this.$route.query.id).then(response => {
        if (response.success) {
          // this.isChecked = true
          this.item = response.data
          this.activeIndex = this.item.position
          this.app_status = response.data.application_status
          this.sys_check = response.data.sys_check
          if (this.activeIndex >= 4) {
            this.isDisabled = true
            this.app_status = 2
          }
          if (this.item.seed_source === null) {
            this.item.seed_source = Object.assign({},
              {
                user_id: this.$store.state.Auth.authUser.id,
                seed_certification_tag_number: null,
                seed_certification_tag_number_bn: null,
                seed_type_id: '',
                seed_name_id: '',
                seed_variety_id: '',
                seed_class_id: '',
                lot_number: '',
                validity_period: '',
                certificate_issue_date: '',
                net_weight_seed: '',
                unit_id: '',
                seed_producer_name: '',
                seed_producer_name_bn: '',
                seed_producer_address: '',
                seed_producer_address_bn: '',
                upload_photo: '',
                class_of_seeds: '',
                sowing_planting: '',
                s_certificate_issue_date: '',
                draft: '',
                general_info_id: ''
              }
            )
          } else {
            this.basicSeed.seed_type_id = this.item.seed_source.seed_type_id
            this.basicSeed.seed_name_id = this.item.seed_source.seed_name_id
            this.basicSeed.seed_variety_id = this.item.seed_source.seed_variety_id
            this.basicSeed.seed_class_id = this.item.seed_source.seed_class_id
          }
          if (this.item.before_showing_planting === null) {
            this.item.before_showing_planting = Object.assign({},
              {
                user_id: this.$store.state.Auth.authUser.id,
                mouja_scheme_name: '',
                mouja_scheme_name_bn: '',
                draft: '',
                production_year: '',
                seed_producer_name: '',
                b_seed_producer_name: '',
                seed_producer_name_bn: '',
                block_sub_area: '',
                block_sub_area_bn: '',
                gps_location: '',
                b_latitude: 23.8103,
                b_longitude: 90.4125,
                production_season_id: '',
                seed_name: '',
                seed_variety_name: '',
                seed_class_name: '',
                seed_production_area: '',
                b_certificate_issue_date: '',
                b_unit_id: '',
                amount_seed_production: '',
                general_info_id: ''
              }
            )
          } else {
            // this.center = [this.item.before_showing_planting.b_latitude, this.item.before_showing_planting.b_longitude]
          }
          if (this.item.plot_location === null) {
            this.item.plot_location = Object.assign({},
              {
                scheme_id: 0,
                mouja_scheme_name_bn: '',
                mouja_scheme_name: '',
                total_land: 0,
                plot_details: [
                    {
                        plot_number: null,
                        amount_of_land: '',
                        unit_id: '',
                        seed_variety_id: '',
                        date_of_harvest: '',
                        gps_location: '',
                        draft: '',
                        unit: '',
                        unit_bn: '',
                        variety: '',
                        variety_bn: '',
                        general_info_id: ''
                    }
                ]
              }
            )
          }
          if (this.item.plot_location !== null) {
            if (this.item.plot_location.plot_details !== null) {
              const details = this.item.plot_location.plot_details.map(item => {
                const qtyObj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(qualityUnitList => qualityUnitList.value === parseInt(item.unit_id))
                const seedPacket = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.find(seedVarietyList => seedVarietyList.value === parseInt(item.seed_variety_id))
                const detailData = {}
                if (typeof qtyObj !== 'undefined') {
                  detailData.unit = qtyObj.text_en
                  detailData.unit_bn = qtyObj.text_bn
                } else {
                  detailData.unit = ''
                  detailData.unit_bn = ''
                }
                if (typeof seedPacket !== 'undefined') {
                  detailData.variety = seedPacket.text_en
                  detailData.variety_bn = seedPacket.text_bn
                } else {
                  detailData.variety = ''
                  detailData.variety_bn = ''
                }
                return Object.assign({}, item, detailData)
              })
              this.item.plot_location.plot_details = details
            }
          }
          this.isChecked = true
          if (this.item.document === null) {
            this.item.document = Object.assign({},
              {
                dealer_reg_certificate: '',
                seed_purchase_receipt: '',
                source_tag_doc: '',
                mouja_map: ''
              }
            )
          }
          if (this.item.payment === null) {
            this.item.payment = Object.assign({},
              {
                draft: 1,
                payment_status: 1,
                treasury_challan_code: '',
                treasury_deposited_amount: '',
                treasury_challan_number: '',
                treasury_challan_date: '',
                treasury_challan_file: '',
                bank_id: '',
                branch_id: '',
                ekpay_deposited_amount: '',
                ekpay_transaction_number: '',
                ekpay_transaction_date: ''
              }
            )
          }
          if (this.item.harvesting === null) {
            // this.activeIndex = 6
            this.item.harvesting = Object.assign({},
              {
                user_id: this.$store.state.Auth.authUser.id,
                seed_pro_center_name: '',
                seed_pro_center_name_bn: '',
                seed_storage_place: '',
                seed_storage_place_bn: '',
                seed_collection_year: '',
                produc_area_space_name: '',
                produ_area_space_name_bn: '',
                produc_area_space_address: '',
                produc_area_space_address_bn: '',
                seed_name: '',
                seed_variety_name: '',
                seed_class_name: '',
                lot_no_provided: '',
                seed_amount_in_lot: '',
                center_id: 0,
                lot_unit_measurement: 0,
                unit_id: 0,
                amount_seeds_per_certification_tag: '',
                packet_id: '',
                total_demand_certification_work: '',
                general_info_id: this.generalId
              }
            )
          }
          if (this.item.showingplanting === null) {
            this.item.showingplanting = Object.assign({},
              {
                user_id: this.$store.state.Auth.authUser.id,
                fiscal_year_id: '',
                seed_producer_name: '',
                seed_producer_name_bn: '',
                block_sub_area: '',
                block_sub_area_bn: '',
                gps_location: '',
                latitude: 23,
                longitude: 90,
                production_season_id: '',
                seed_type_id: '',
                seed_name_id: '',
                seed_variety_id: '',
                seed_class_id: '',
                seed_source_name: '',
                seed_source_name_bn: '',
                seed_lot_no: '',
                producer_name: '',
                producer_name_bn: '',
                producer_address: '',
                producer_address_bn: '',
                plot_number: '',
                seed_production_area: '',
                unit_id: '',
                draft: 1,
                planting_date: '',
                general_info_id: ''
              }
            )
          }
          if (this.item.flowers === null) {
            this.item.flowers = Object.assign({},
              {
                user_id: this.$store.state.Auth.authUser.id,
                fiscal_year_id: '',
                seed_producer_name: '',
                seed_producer_name_bn: '',
                block_sub_area: '',
                block_sub_area_bn: '',
                gps_location: '',
                latitude: 23,
                longitude: 90,
                production_season_id: '',
                seed_type_id: '',
                seed_name_id: '',
                seed_variety_id: '',
                seed_class_id: '',
                seed_source_name: '',
                seed_source_name_bn: '',
                seed_lot_no: '',
                producer_name: '',
                producer_name_bn: '',
                producer_address: '',
                producer_address_bn: '',
                plot_number: '',
                seed_production_area: '',
                unit_id: '',
                possible_seed_receipt: '',
                possible_date_harvesting: '',
                haulm_pulling_potato_date: '',
                draft: 1,
                general_info_id: ''
              }
            )
          }
          if (this.activeIndex === 1) {
            this.getFiscalYear()
          }
          this.loadItem = true
        }
      })
    },
    currentDate () {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      return date
    },
    async tabSubmitGeneral () {
      if (this.item.position < 4) {
        let result = null
        result = await this.$refs.social.generalSubmit()
        if (result.success) {
          this.generalId = result.data.applicantAddress.general_info_id
          this.item.id = result.data.applicantAddress.general_info_id
          this.item.general_id = result.data.applicantAddress.general_info_id
          this.loadItem1 = true
          this.activeIndex = 1
          this.getFiscalYear()
          return true
        }
      } else if (parseInt(this.activeIndex) < this.item.position) {
        return true
      }
    },
    async tabSubmitSeedInfo () {
      if (this.item.position < 4) {
        let result = null
        result = await this.$refs.field.infoSubmit()
        if (result.success) {
          this.basicSeed.seed_type_id = result.data.seed_type_id
          this.basicSeed.seed_name_id = result.data.seed_name_id
          this.basicSeed.seed_variety_id = result.data.seed_variety_id
          this.basicSeed.seed_class_id = result.data.seed_class_id
          return true
        }
      } else if (parseInt(this.activeIndex) < this.item.position) {
        return true
      }
    },
    async tabSubmitPayment () {
      if (parseInt(this.activeIndex) < this.item.position) {
          return true
      } else {
        let result = null
        result = await this.$refs.payment.paymentSubmit()
        if (result.success) {
          this.$swal({
            title: 'Payment Successfully! Please Wait for admin approval',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'No',
            focusConfirm: false
          }).then((resultSwal) => {
            this.$router.push('/research-farmer/seed-certification/field-application-list')
          })
        }
      }
    },
    async tabSubmitDocumentAttach () {
      if (this.item.position < 4) {
        let result = null
        result = await this.$refs.doc.docSubmit()
        if (result.success) {
          this.loadItem3 = true
          return true
        }
      } else if (parseInt(this.activeIndex) < this.item.position) {
        return true
      }
    },
    async tabSubmitAfterSowing () {
      if (parseInt(this.activeIndex) < this.item.position) {
        return true
      } else {
        let result = BIconNutFill
        result = await this.$refs.sowing.afterSowingSubmit()
        if (result.success) {
          this.$swal({
            title: 'Please Wait For Inspection Report 1',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'No',
            focusConfirm: false
          }).then((resultSwal) => {
            this.$router.push('/research-farmer/seed-certification/field-application-list')
          })
        }
      }
    },
    async tabSubmitAfterFlowersCome () {
      if (parseInt(this.activeIndex) < this.item.position) {
        return true
      } else {
        let result = BIconEmojiNeutralFill
        result = await this.$refs.flowers.afterFlowerComesSubmit()
        if (result.success) {
          this.$swal({
            title: 'Please Wait For Inspection Report 2',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'No',
            focusConfirm: false
          }).then((resultSwal) => {
            this.$router.push('/research-farmer/seed-certification/field-application-list')
          })
        }
      }
    },
    async tabSubmitAfterHarvesting () {
      if (parseInt(this.activeIndex) < this.item.position) {
          return true
      } else {
        let result = null
        result = await this.$refs.harvest.afterHarvestingSubmit()
        if (result.success) {
          this.$swal({
            title: 'Successfully Done!',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'No',
            focusConfirm: false
          }).then((resultSwal) => {
            this.$router.push('/research-farmer/seed-certification/field-application-list')
          })
        }
      }
    },
    async addForm () {
      let result = null
      result = await this.$refs.admissionsss.final_submit()
      if (result.success) {
        this.$router.push({ path: '/hospital-disability-service/consultancy-therapy-center/consultancy-management' })
      }
    },
    setLang () {
     this.lang = this.$i18n.locale
    }
  }
}
</script>

<style scoped>
  .vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn-custom {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 2px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    min-width: 140px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    font-size: 14px;
}
</style>
