<template>
    <b-overlay :show="loading">
    <div v-if="sys_check === 4">
            <p><br></p>
            <center><h1><p>{{$t('external_research.pay_verify')}}</p></h1>
            </center>
            <p><br></p>
            <p><br></p>
            <p><br></p>
    </div>
    <div v-else>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(paymentSubmit)" @reset.prevent="reset">
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.payment') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-form-group>
                                    <template v-slot:label>
                                        {{ $t('certify_form.payment_method') }}
                                    </template>
                                    <b-form-radio-group
                                        v-model="payment.payment_status"
                                        class="mr-5"
                                        :options="optionList"
                                        name="radio-inline"
                                    ></b-form-radio-group>
                                </b-form-group>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row v-show="payment.payment_status === 1">
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.bank') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col sm="3">
                                        <ValidationProvider name="Traeasury Challan Code" vid="treasury_challan_code" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="treasury_challan_code" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.treasury_challan_code') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="number"
                                                    id="treasury_challan_code"
                                                    v-model="payment.treasury_challan_code"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Amount" vid="amount" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="Amount" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.treasury_deposited_amount') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="payment.amount"
                                                id="amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Treasury Challan Number" vid="treasury_challan_number" :rules="payment.payment_status === 1?'required':''">
                                        <b-form-group class="row" label-cols-sm="12" label-for="treasury_challan_number" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.treasury_challan_number') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                v-model="payment.treasury_challan_number"
                                                id="treasury_challan_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Treasury Challan Date" vid="treasury_challan_date" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="treasury_challan_date" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.treasury_challan_date') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    class="date-picker"
                                                    v-model="payment.treasury_challan_date"
                                                    id="treasury_challan_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="treasury_challan_file" vid="treasury_challan_file" ref="treasury_challan_file" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="treasury_challan_file" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.treasury_challan_file') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-file
                                                    id="treasury_challan_file"
                                                    @change="onTreasuryChange"
                                                    v-model="payment.treasury_challan_file"
                                                    :placeholder="payment.treasury_challan_file ? payment.treasury_challan_file : 'Choose a file...'"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-file>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Bank Name" vid="bank_name" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="bank_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.bank_name') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="payment.bank_id"
                                                :options="bankNameList"
                                                id="bank_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Branch Name" vid="branch_name" :rules="payment.payment_status === 1?'required':''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="branch_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{ $t('certify_form.branch_name') }}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="payment.branch_id"
                                                :options="branchNameList"
                                                id="branch_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row style="
                    display: flex;
                    flex-wrap: wrap;"
                    v-show="payment.payment_status === 2"
                >
                    <b-col lg="12" sm="12">
                    <iq-card>
                    <b-col md="12" lg="12" sm="12">
                        <table v-if="history.length" class="table table-bordered">
                        <tr>
                            <th colspan="4" class="text-center">History</th>
                        </tr>
                            <tr>
                                <th>{{ $t('certify_form.fee_name') }}</th>
                                <th>{{ $t('globalTrans.amount') }}</th>
                                <th>{{ $t('globalTrans.status') }}</th>
                                <th>{{ $t('globalTrans.action') }}</th>
                            </tr>
                            <tr v-for="(item, index) in history" :key="index">
                                <td>{{ getFeeName(payment.payment_type_id) }}</td>
                                <td>{{ $n(item.amount) }}</td>
                                <td>
                                <span class="badge badge-success" v-if="item.pay_status === 'success'">{{ $t('globalTrans.success') }}</span>
                                <span class="badge badge-warning" v-else>{{ $t('globalTrans.pending') }}</span>
                                </td>
                                <td class="text-center">
                                <b-button v-if="item.pay_status === 'pending'" @click="saveFormData(2, item.transaction_no)" variant="primary" class="mr-2 btn-sm">{{ $t('externalLrcpn.pay_now') }}</b-button>
                                <p v-else class="badge badge-success">{{ $t('globalTrans.paid') }}</p>
                                </td>
                            </tr>
                        </table>
                        <table v-else class="table table-bordered">
                            <tr>
                                <th style="width:50%">{{ $t('certify_form.fee_name') }}</th>
                                <th style="width:50%">{{ $t('globalTrans.amount') }}</th>
                            </tr>
                            <tr>
                                <td style="width:50%">{{ getFeeName(payment.payment_type_id) }}</td>
                                <td style="width:50%">{{ $n(payment.amount) }}</td>
                            </tr>
                        </table>
                    </b-col>
                    </iq-card>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </div>
    </b-overlay>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storePaymentInfo, paymentStore } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'
export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: ['payment', 'generalId', 'sys_check'],
    data () {
        return {
            loading: false,
            tmpTreasuryFile: [],
            branchNameList: [],
            history: [],
            options: [
                {
                    value: 1,
                    text: 'Bank',
                    text_en: 'Bank',
                    text_bn: 'ব্যাংক'
                },
                {
                    value: 2,
                    text: 'ekPay',
                    text_en: 'ekPay',
                    text_bn: 'একপে'
                }
            ]
        }
    },
    created () {
        if (!this.payment.id) {
            const feePolicy = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.feePolicy.find(policy => policy.payment_type_id === 1)
            this.payment.amount = typeof feePolicy !== 'undefined' ? feePolicy.amount : 0
            this.payment.payment_type_id = feePolicy.payment_type_id
            this.payment.fee_id = feePolicy.id
        } else {
            const feePolicy = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.feePolicy.find(policy => policy.payment_type_id === 1)
            this.payment.amount = typeof feePolicy !== 'undefined' ? feePolicy.amount : 0
            this.payment.payment_type_id = feePolicy.payment_type_id
            this.payment.fee_id = feePolicy.id
        }
        this.branchNameList = this.getBranch(this.payment.bank_id)
    },
    mounted () {
        flatpickr('.date-picker', {})
        flatpickr('#ekpay_transaction_date', {})
        core.index()
    },
    watch: {
        'payment.bank_id': function (newVal) {
            this.branchNameList = this.getBranch(newVal)
        }
    },
    computed: {
        bankNameList: function () {
            const prod = this.$store.state.ExternalAgriResearch.commonObjCommonConfig.bankList
            return prod.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        optionList: function () {
            const prod = this.options
            return prod.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
    },
    methods: {
        getFeeName (id) {
            const paymentList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Field Application Fee' : 'আবেদন ফি' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Seed Testing Fee' : 'বীজ পরীক্ষার ফী' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Tag Print Fee' : 'ট্যাগ প্রিন্ট ফী' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Subscription Fee' : 'সাবস্ক্রিপশন ফী' }
            ]
            const fee = paymentList.find(item => item.value === id)
            if (typeof fee === 'undefined') {
                return ''
            } else {
                return fee.text
            }
        },
        getBranch (id) {
            let branchObj = ''
            branchObj = this.$store.state.ExternalAgriResearch.commonObjCommonConfig.branchList.filter(branch => branch.bank_id === parseInt(id))
            return branchObj.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        getPaymentInfo () {
            this.loading = false
            if (this.itemData.id) {
                this.data = { ...this.itemData, ...this.itemData.payment, table_id: this.itemData.id }
            }
        },
        async paymentSubmit (isNext = 1) {
            if (this.payment.payment_status === 1) {
                const validationResult = await this.$refs.form.validate()
                if (!validationResult) return false

                this.loading = true
                let mainForm = {}
                if (!isNext) {
                    this.data.draft = isNext
                }
                mainForm = { ...this.payment, user_id: this.$store.state.Auth.authUser.id, general_info_id: this.generalId, type_id: 1 }

                var formData = new FormData()
                Object.keys(mainForm).map(key => {
                    if (key === 'treasury_challan_file') {
                        if (this.tmpTreasuryFile.name) {
                            formData.append('treasury_challan_file', this.tmpTreasuryFile)
                        } else {
                            formData.append(key, this.payment[key])
                        }
                    } else {
                        formData.append(key, mainForm[key])
                    }
                })

                let result = null
                result = await RestApi.postData(agriResearchServiceBaseUrl, storePaymentInfo, formData)
                if (result.success) {
                    this.$toast.success({
                    title: 'Success',
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                    })
                    this.loading = false
                if (result.payment) {
                    window.vm.$swal({
                            title: 'Payment Successfull',
                            showCancelButton: true,
                            confirmButtonText: window.vm.$t('globalTrans.yes'),
                            cancelButtonText: window.vm.$t('globalTrans.no'),
                            focusConfirm: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/research-farmer/seed-certification/field-application-list'
                            }
                        })
                        return result
                } else {
                        return result
                }
                } else {
                    this.$toast.error({
                    title: 'Error',
                    message: this.$t('globalTrans.form_error_msg'),
                    color: '#D6E09B'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
                this.loading = false
            }
            if (this.payment.payment_status === 2) {
                let tmpPay = {}
                    if (this.generalId) {
                        tmpPay = Object.assign(this.payment, { transId: '' }, { general_info_id: this.generalId }, { type_id: 1 })
                    } else {
                        tmpPay = Object.assign(this.payment, { transId: '' }, { general_info_id: '' })
                    }
                let result = null
                const loadingState = { loading: true, listReload: true }
                result = await RestApi.postData(agriResearchServiceBaseUrl, paymentStore, tmpPay)
                this.saveloading = false
                loadingState.listReload = true
                this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false })
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    if (result.success === 2) {
                    this.$toast.success({
                        title: 'Success',
                        message: result.message
                    })
                    } else {
                    window.location = result.url
                    }
                    this.$bvModal.hide('modal-payment')
                } else {
                    this.$refs.form.setErrors(result.errors)
                    this.$toast.error({
                    title: 'Error',
                    message: result.message
                    })
                }
            }
        },
        onTreasuryChange (e) {
            this.tmpTreasuryFile = e.target.files[0]
        }
    }
}
</script>

<style>

</style>
