<template>
<b-overlay :show="loading">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(afterHarvestingSubmit)" @reset.prevent="reset">
            <b-row>
                <b-col sm="12">
                    <iq-card>
                        <template v-slot:headerTitle>
                            <div class="text-center">
                                <h5 class="card-title">{{ $t('certify_form.after_harvesting') }}</h5>
                            </div>
                        </template>
                        <template v-slot:body>
                            <b-row>
                                <b-col sm="3" v-if="govtId">
                                    <ValidationProvider name="Seed Processing Center" vid="processing_center" rules="required|min_value:1">
                                        <b-form-group class="row" label-cols-sm="12" label-for="processing_center" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.processing_center')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                v-model="harvestData.center_id"
                                                :options="seedProcessingCentersList"
                                                id="processing_center"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3" v-if="!govtId">
                                    <ValidationProvider name="Seed Processing Center Name(En)" vid="seed_pro_center_name" rules="required">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_pro_center_name" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_pro_center_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="seed_pro_center_name"
                                            v-model="harvestData.seed_pro_center_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3" v-if="!govtId">
                                    <ValidationProvider name="Seed Processing Center Name(Bn)" vid="seed_pro_center_name_bn" rules="required">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_pro_center_name_bn" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_pro_center_name_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="seed_pro_center_name_bn"
                                            v-model="harvestData.seed_pro_center_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Seed Collection Year" vid="seed_collection_year">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_collection_year" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_collection_year')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            readonly
                                            id="seed_collection_year"
                                            v-model="fiscalYear"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            class="date-picker"
                                            disabled
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Seed Storage Place(En)" vid="seed_storage_place" rules="required">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_storage_place" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_storage_place')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="seed_storage_place"
                                            v-model="harvestData.seed_storage_place"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Seed Storage Place(Bn)" vid="seed_storage_place_bn" rules="required">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_storage_place_bn" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_storage_place_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="seed_storage_place_bn"
                                            v-model="harvestData.seed_storage_place_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Seed Name" vid="seed_name_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_name_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('research_seed.seed_name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="seedName"
                                                id="fiscal_year"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Seed Variety" vid="seed_variety_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_variety_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('external_research.seed_variety')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="seedVariety"
                                                id="fiscal_year"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Seed Class" vid="seed_class_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_class_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('external_research.seed_class')}}
                                            </template>
                                            <b-form-select
                                            plain
                                                v-model="harvestData.seed_class_id"
                                                :options="seedClassList"
                                                id="seed_class_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Production Area or Space Name" vid="production_area">
                                    <b-form-group class="row" label-cols-sm="12" label-for="production_area" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.production_area')}}
                                        </template>
                                        <b-form-input
                                            readonly
                                            id="production_area"
                                            v-model="areaName"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Seed Amount In Lot " vid="seed_amount_in_lot" rules="required|min_value:1">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_amount_in_lot" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.seed_amount_in_lot')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            id="seed_amount_in_lot"
                                            v-model="harvestData.seed_amount_in_lot"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Unit of Measurement" vid="lot_unit_measurement" rules="required|min_value:1">
                                    <b-form-group class="row" label-cols-sm="12" label-for="lot_unit_measurement" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.unit_measurement')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            type="number"
                                            v-model="harvestData.lot_unit_measurement"
                                            :options="unitList"
                                            id="lot_unit_measurement"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Lot No Provided by Organization or Institution" vid="lot_no_provided" rules="required">
                                    <b-form-group class="row" label-cols-sm="12" label-for="lot_no_provided" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.lot_no_provided')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="lot_no_provided"
                                            v-model="harvestData.lot_no_provided"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </template>
                    </iq-card>
                </b-col>
            </b-row>
        </b-form>
    </ValidationObserver>
</b-overlay>

</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeAfterHarvestingInfo } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'
export default {
    props: ['harvestData', 'generalId', 'basicSeed', 'seed_source', 'fiscalYear', 'govtId', 'areaName'],
    data () {
        return {
            loading: false,
            seedVariety: '',
            seedName: '',
            year: ''
        }
    },
    components: {
        ValidationObserver, ValidationProvider
    },
    created () {
        this.harvestData.seed_name_id = this.basicSeed.seed_name_id
        this.harvestData.seed_variety_id = this.basicSeed.seed_variety_id
        this.harvestData.seed_class_id = this.basicSeed.seed_class_id
    },
    mounted () {
        this.seedName = this.getSeedName(this.harvestData.seed_name_id)
        this.seedVariety = this.getVariety(this.harvestData.seed_name_id)
        flatpickr('.date-picker', {})
        core.index()
    },
    computed: {
        unitList: function () {
            const units = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.filter(item => item.status === 0)
            return units.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedClassList: function () {
            const classes = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedClassNameList.filter(item => item.status === 0)
            return classes.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedNameList: function () {
            const seedNameList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.filter(seed => seed.status === 0)
            return seedNameList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedProcessingCentersList: function () {
            const seedNameList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedProcessingCentersList.filter(seed => seed.status === 0)
            return seedNameList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
    },
    methods: {
        getUnit (id) {
            const unit = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.find(item => item.status === 0 && item.value === parseInt(id))
            var val = unit.text_en
            this.harvestData.lot_offer.seed_amount = this.harvestData.seed_amount_in_lot.concat(' ' + val)
        },
        getSeedName (id) {
            const seed = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.find(item => item.value === parseInt(id))
            return seed !== undefined ? this.$i18n.locale === 'en' ? seed.text_en : seed.text_bn : ''
        },
        getVariety (nameId) {
            const list = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.find(seed => seed.seed_name_id === parseInt(nameId))
            return list !== undefined ? this.$i18n.locale === 'en' ? list.text_en : list.text_bn : ''
        },
        async afterHarvestingSubmit (isNext = 1) {
            var check = await this.$refs.form.validate()
            if (check) {
            // let mainForm = {}
                this.loading = true
                if (!isNext) {
                        this.harvestData.draft = isNext
                }
                // mainForm = { ...this.harvestData, lot_offer_details: this.items }
                let result = null
                result = await RestApi.postData(agriResearchServiceBaseUrl, storeAfterHarvestingInfo, this.harvestData)
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    this.loading = false
                    return result
                } else {
                    this.$refs.form.setErrors(result.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#D6E09B'
                    })
                }
                this.loading = false
            }
        }
    }
}
</script>
