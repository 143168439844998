<template>
    <b-overlay :show="loading">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(infoSubmit)" @reset.prevent="reset">
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.plot_location') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col sm="4" v-if="govtId">
                                        <ValidationProvider name="Scheme List" vid="scheme_id">
                                            <b-form-group class="row" label-cols-sm="12" label-for="scheme_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('certify_form.mouja_scheme')}} <span class="text-danger">*</span>
                                                </template>
                                                 <b-form-select
                                                    plain
                                                    v-model="plot_location.scheme_id"
                                                    :options="schemeList"
                                                    id="scheme_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="!govtId">
                                        <ValidationProvider name="Mouja/Scheme Name" vid="mouja_scheme_name">
                                        <b-form-group class="row" label-cols-sm="12" label-for="mouja_scheme_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.mouja_scheme_name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="plot_location.mouja_scheme_name"
                                                id="mouja_scheme_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="!govtId">
                                        <ValidationProvider name="Mouja/Scheme Name(Bn)" vid="mouja_scheme_name_bn">
                                        <b-form-group class="row" label-cols-sm="12" label-for="mouja_scheme_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.mouja_scheme_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="plot_location.mouja_scheme_name_bn"
                                                id="mouja_scheme_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.seed_source') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Certificaton Tag Number" vid="seed_certification_tag_number" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_certification_tag_number" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.seed_certification_tag_number')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_certification_tag_number"
                                                    id="seed_certification_tag_number"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- <b-col md="6">
                                        <ValidationProvider name="Seed Certificaton Tag Number(Bn)" vid="seed_certification_tag_number_bn" rules="required">
                                            <b-form-group class="row" label-cols-sm="6" label-for="seed_certification_tag_number_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.seed_certification_tag_number_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_certification_tag_number_bn"
                                                    id="seed_certification_tag_number_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col> -->
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Type" vid="seed_type_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_type_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_type_id"
                                                    :options="seedTypeList"
                                                    id="seed_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="seed_source.seed_type_id != 8" >
                                        <ValidationProvider name="Seed Name" vid="seed_name_id" :rules="seed_source.seed_type_id != 8 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_name_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_name')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_name_id"
                                                    :options="seedNameList"
                                                    id="seed_name_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="seed_source.seed_type_id == 8">
                                        <ValidationProvider name="Seed Name Others" vid="seed_name_others" :rules="seed_source.seed_type_id == 8 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_name_others" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_name')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_name_others"
                                                    id="seed_name_others"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col v-if="seed_source.seed_type_id != 8" sm="4">
                                        <ValidationProvider name="Seed Variety" vid="seed_variety_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_variety_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('external_research.seed_variety')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_variety_id"
                                                    :options="seedVarietyList"
                                                    id="seed_variety_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="seed_source.seed_type_id == 8">
                                        <ValidationProvider name="Seed Variety Others" vid="seed_variety_others" :rules="seed_source.seed_type_id == 8 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_variety_others" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('external_research.seed_variety')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_variety_others"
                                                    id="seed_variety_others"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Class" vid="seed_class_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_class_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('external_research.seed_class')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="seed_source.seed_class_id"
                                                :options="seedClassList"
                                                id="seed_class_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Lot Number" vid="lot_number" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="lot_number" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.lot_number')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                type="number"
                                                v-model="seed_source.lot_number"
                                                id="lot_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Source Name(En)" vid="seed_source_name" rules="">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_source_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_source_name_en')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                id="seed_source_name"
                                                v-model="seed_source.seed_source_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Source Name(Bn)" vid="seed_source_name_bn" rules="">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_source_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_source_name_bn')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                id="seed_source_name_bn"
                                                v-model="seed_source.seed_source_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Certificate Issue Date" vid="certificate_issue_date" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="certificate_issue_date" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.certificate_issue_date')}} <span class="text-danger">*</span>
                                            </template>
                                            <flat-pickr class="form-control"
                                            v-model="seed_source.certificate_issue_date"
                                            id="certificate_issue_date"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </flat-pickr>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Validity Period" vid="validity_period" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="validity_period" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.validity_period')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                type="number"
                                                v-model="seed_source.validity_period"
                                                id="validity_period"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Net Weight Of Seed" vid="net_weight_seed" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="net_weight_seed" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.net_weight_seed')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                type="number"
                                                v-model="seed_source.net_weight_seed"
                                                id="net_weight_seed"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Unit of Measurement " vid="unit_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="plot_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.unit_of_measurement')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.unit_id"
                                                    :options="unitList"
                                                    id="plot_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Producer Name" vid="seed_producer_name" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_producer_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_producer_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                v-model="seed_source.seed_producer_name"
                                                id="seed_producer_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Producer Name(Bn)" vid="seed_producer_name_bn" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_producer_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_producer_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="seed_source.seed_producer_name_bn"
                                                id="seed_producer_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Producer Address(En)" vid="seed_producer_address" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_producer_address" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_producer_address_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="seed_source.seed_producer_address"
                                                id="seed_producer_address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Producer Address(Bn)" vid="seed_producer_address_bn" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_producer_address_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_producer_address_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="seed_source.seed_producer_address_bn"
                                                id="seed_producer_address_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Upload Photo" vid="upload_photo">
                                        <b-form-group class="row" label-cols-sm="12" label-for="upload_photo" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{ $t('certify_form.upload_photo') }}
                                            <!-- <v-slot v-if="main_form.upload_photo !== null">
                                                <a :href="baseUrl + 'download-attachment?file=uploads/general-information/attachment/original/' + main_form.upload_photo" title="Evaluation Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                            </v-slot> -->
                                            </template>
                                            <b-form-file
                                            id="upload_photo"
                                            @change="onPhotoChange"
                                            :placeholder="seed_source.upload_photo ? seed_source.upload_photo : 'Choose a file...'"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-file>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Class Of Seeds" vid="class_of_seeds" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="class_of_seeds" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.class_of_seeds')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="seed_source.class_of_seeds"
                                                :options="classes"
                                                id="class_of_seeds"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Sowing/Planting Date" vid="sowing_planting" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="sowing_planting" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.sowing_planting')}} <span class="text-danger">*</span>
                                            </template>
                                            <flat-pickr class="form-control"
                                            v-model="seed_source.sowing_planting"
                                            id="sowing_planting"
                                            >
                                            </flat-pickr>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.plot_field_seed_details') }}</h5>
                                </div>
                            </template>
                            <div class="table-responsive">
                                <table class="table table-bordered table-sm mb-0">
                                    <thead class="thead">
                                        <tr>
                                            <th style="width:15%">{{$t('certify_form.plot_number')}} <span class="text-danger">*</span></th>
                                            <th style="width:16%"> {{$t('external_research.seed_variety')}} <span class="text-danger">*</span></th>
                                            <th style="width:15%">{{$t('certify_form.amount_of_land')}} <span class="text-danger">*</span></th>
                                            <!-- <th style="width:18%">{{$t('certify_form.unit_measurement')}} <span class="text-danger">*</span></th> -->
                                            <th style="width:16%">{{$t('certify_form.date_of_harvest')}}</th>
                                            <th style="width:5%"></th>
                                        </tr>
                                    </thead>
                                    <tr v-for="(plot,i) in plot_location.plot_details" :key="i">
                                        <td>
                                            <keep-alive>
                                                <ValidationProvider :vid="'plot_number' + index" rules="required" name="Plot No (En)">
                                                    <div label-for="plot_number" slot-scope="{ valid, errors }">
                                                        <b-form-input
                                                            type="number"
                                                            plain
                                                            v-model="plot.plot_number"
                                                            id="plot_number"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </keep-alive>
                                        </td>
                                        <td>
                                            <keep-alive>
                                                <ValidationProvider :vid="'seed_variety_id' + index"  name="Seed Variety Id">
                                                    <div label-for="seed_variety_id" slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                            plain
                                                            v-model="plot.seed_variety_id"
                                                            :options="seedVarietyList"
                                                            id="seed_variety_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-select>
                                                    </div>
                                                </ValidationProvider>
                                            </keep-alive>
                                        </td>
                                        <td>
                                            <keep-alive>
                                                <ValidationProvider :vid="'amount_of_land' + index" rules="required"  name="Course name (Bn)">
                                                    <div label-for="amount_of_land" slot-scope="{ valid, errors }">
                                                        <b-form-input
                                                            type="number"
                                                            plain
                                                            v-model="plot.amount_of_land"
                                                            id="amount_of_land"
                                                            @input="updateVal()"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </keep-alive>
                                        </td>
                                        <!-- <td>
                                            <keep-alive>
                                                <ValidationProvider :vid="'quality_unit' + index"  name="Unit Measurment">
                                                    <div label-for="unit_id" slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                            plain
                                                            v-model="plot.unit_id"
                                                            :options="unitList"
                                                            id="unit_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-select>
                                                    </div>
                                                </ValidationProvider>
                                            </keep-alive>
                                        </td> -->
                                        <td>
                                            <keep-alive>
                                                <ValidationProvider :vid="'date_of_harvest' + index" name="Course Details (Bn)">
                                                    <div slot-scope="{ valid, errors }">
                                                            <flat-pickr class="form-control"
                                                            v-model="plot.date_of_harvest"
                                                            id="date_of_harvest"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </flat-pickr>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        <div class="error invalid-feedback" role="alert">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </keep-alive>
                                        </td>
                                        <td>
                                            <button v-if="plot_location.plot_details.length>0" @click="removeplot(i)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-window-close m-0"></i> </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" class="text-right">
                                            <button type="button" class="btn btn-sm btn-success" @click="addPlot">
                                                <i class="fa fa-plus" aria-hidden="true"></i> {{ $t('globalTrans.add_more') }}
                                            </button>
                                        </td>
                                    </tr>
                                    <tfoot class="thead">
                                        <tr>
                                            <th style="width:15%" colspan="3" class="text-right">{{ $t('certify_form.total_amount_of_land') }}</th>
                                            <th style="width:15%" class="text-right">{{ total }}</th>
                                            <th></th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="text-dark">{{ $t('certify_form.before_sowing_planting') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col sm="4">
                                        <ValidationProvider name="Production Year" vid="fiscal_year" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="fiscal_year" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('certify_form.production_year')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    readonly
                                                    v-model="plotInfo.production_year"
                                                    id="fiscal_year"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Zone or Area Name(En)" vid="b_seed_producer_name" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="b_seed_producer_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.zone_or_area_name_en')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.seed_producer_name"
                                                    id="seed_producer_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Zone or Area Name(Bn)" vid="seed_producer_name_bn" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_producer_name_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.zone_or_area_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.seed_producer_name_bn"
                                                    id="seed_producer_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Block Sub Area(En)" vid="block_sub_area" rules="">
                                            <b-form-group class="row" label-cols-sm="12" label-for="block_sub_area" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.block_sub_area')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.block_sub_area"
                                                    id="block_sub_area"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Block Sub Area(Bn)" vid="block_sub_area_bn" rules="">
                                            <b-form-group class="row" label-cols-sm="12" label-for="block_sub_area_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.block_sub_area_bn')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.block_sub_area_bn"
                                                    id="block_sub_area_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Type" vid="production_season_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="production_season_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.production_season')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="plotInfo.production_season_id"
                                                    :options="productionSeasonList"
                                                    id="production_season_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Type" vid="seed_type_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_type_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_type_id"
                                                    :options="seedTypeList"
                                                    id="seed_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="seed_source.seed_type_id != 8">
                                        <ValidationProvider name="Seed Name" vid="seed_name_id" :rules="seed_source.seed_type_id != 8 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_name_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_name')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_name_id"
                                                    :options="seedNameList"
                                                    id="seed_name_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="seed_source.seed_type_id == 8">
                                        <ValidationProvider name="Seed Name" vid="seed_name_id" :rules="seed_source.seed_type_id == 8 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_name_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_name')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_name_others"
                                                    id="seed_name_others"
                                                    disabled
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="seed_source.seed_type_id != 8">
                                        <ValidationProvider name="Seed Variety" vid="seed_variety_id" :rules="seed_source.seed_type_id != 8 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_variety_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('external_research.seed_variety')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_variety_id"
                                                    :options="seedVarietyList"
                                                    id="seed_variety_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4" v-if="seed_source.seed_type_id == 8">
                                        <ValidationProvider name="Seed Variety Others" vid="seed_variety_others" :rules="seed_source.seed_type_id == 8 ? 'required' : ''">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_variety_others" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('external_research.seed_variety')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_variety_others"
                                                    id="seed_variety_others"
                                                    disabled
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Class" vid="seed_class_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_class_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('external_research.seed_class')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="seed_source.seed_class_id"
                                                :options="seedClassList"
                                                id="seed_class_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Seed Production Area" vid="seed_production_area" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_production_area" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.seed_production_area')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.seed_production_area"
                                                    id="seed_production_area"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Certificate Issue Date" vid="b_certificate_issue_date" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="b_certificate_issue_date" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.certificate_issue_date')}} <span class="text-danger">*</span>
                                                </template>
                                                <flat-pickr class="form-control"
                                                v-model="seed_source.certificate_issue_date"
                                                id="b_certificate_issue_date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </flat-pickr>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Amount Of Seed Production" vid="amount_seed_production" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="amount_seed_production" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.amount_seed_production')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="number"
                                                    plain
                                                    v-model="plotInfo.amount_seed_production"
                                                    id="amount_seed_production"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Unit of Measurement" vid="b_unit_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="b_unit_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                   {{$t('research_seed.unit_of_measurement')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="plotInfo.b_unit_id"
                                                    :options="unitList"
                                                    id="b_unit_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <Map :center="center" v-on:child-method="updateParent"/>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </b-overlay>
</template>

<script>
import Map from './components/Map.vue'
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeFieldSeedInfo, storeSourceInfo, storeSCAPlotDetails } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Map
  },
  props: ['itemData', 'generalId', 'seed_source', 'plot_location', 'plotInfo', 'govtId'],
  data () {
    return {
        loading: false,
        mainForm: {
            seed_source: [],
            plot_location: [],
            plotInfo: []
        },
        //   baseUrl: incentiveGrantServiceBaseUrl,
        tmpPhoto: [],
        total: 0,
        data: [],
        plot: {
            plot_number: '',
            amount_of_land: '',
            seed_variety_id: '',
            unit_id: '',
            date_of_harvest: '',
            latitude: '',
            longitude: ''
        },
        center: [90.403409, 23.784506],
        tmpEvaluationAttachment: [],
        orgTypeList: [
            { value: 1, text: this.$t('certify_form.govt') },
            { value: 2, text: this.$t('certify_form.private') }
        ],
        candidateOrg: [
            { value: 1, text: this.$t('certify_form.under_ministry') },
            { value: 2, text: this.$t('certify_form.others') }
        ],
        maritalStatus: [
            { value: 1, text: this.$t('certify_form.single') },
            { value: 2, text: this.$t('certify_form.married') }
        ],
        classes: [
            { value: 'Breeder', text: this.$t('certify_form.breeder') },
            { value: 'Foundation', text: this.$t('certify_form.foundation') },
            { value: 'Certified', text: this.$t('certify_form.certified1') }
        ],
        seedNameList: [],
        seedVarietyList: []
    }
  },
  async created () {
    if (this.seed_source.seed_type_id) {
        this.seedNameList = this.getSeedList(this.seed_source.seed_type_id)
    }
    let total = 0
    this.plot_location.plot_details.map(item => {
        total += parseInt(item.amount_of_land)
    })
    this.total = total
    this.plot_location.total_land = total
    this.getFieldSeedinfo()
    this.getVarietyListdummy()
    flatpickr('.date-picker', {})
  },
  mounted () {
    core.index()
  },
  watch: {
    'seed_source.seed_type_id': function (newVal, oldVal) {
      this.seedNameList = this.getSeedList(newVal)
    },
    'seed_source.seed_name_id': function (newVal, oldVal) {
      this.seedVarietyList = this.getVarietyList(newVal)
    },
    'seed_source.seed_variety_id': function (newVal, oldVal) {
      this.getSeedVariety(newVal)
    }
  },
  computed: {
    schemeList: function () {
        const scheme = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.schemeList
        return scheme.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    unitList: function () {
        const units = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.filter(item => item.status === 0)
        return units.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    seedTypeList: function () {
      const types = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedTypeNameList.filter(seed => seed.status === 0)
      return types.map(item => {
        return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    seedClassList: function () {
     const classes = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedClassNameList
     return classes.map(item => {
        return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
     })
    },
    productionSeasonList: function () {
        const prod = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.productionSeasonList.filter(seed => seed.status === 0)
        return prod.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    }
  },
  methods: {
    updateParent (data) {
      this.center = data
    },
    removeplot (key) {
       this.plot_location.plot_details.splice(key, 1)
       let total = 0
        this.plot_location.plot_details.map(item => {
            total += parseInt(item.amount_of_land)
        })
        this.total = total
        this.plot_location.total_land = total
    },
    addPlot () {
         try {
            this.plot_location.plot_details.push({
                plot_number: '',
                amount_of_land: '',
                unit_id: '',
                seed_variety_id: '',
                date_of_harvest: '',
                gps_location: '',
                latitude: 23.8103,
                longitude: 90.4125,
                draft: '',
                mouja_scheme_name: '',
                mouja_scheme_name_bn: '',
                general_info_id: 0
            })
        } catch (e) {
        }
        // flatpickr('.date-picker', {})
    },
    updateVal () {
        let total = 0
        this.plot_location.plot_details.map(item => {
            total += parseInt(item.amount_of_land)
        })
        this.total = total
        this.plot_location.total_land = total
    },
    async infoSubmit (isNext = 1) {
        const validationResult = await this.$refs.form.validate()
        if (!validationResult) return false
        this.loading = true
        let mainForm = {}
        if (!isNext) {
          this.plotInfo.draft = isNext
          this.seed_source.draft = isNext
        }
        mainForm = { ...this.seed_source, user_id: this.$store.state.Auth.authUser.id, general_info_id: this.generalId }
        var formData = new FormData()
        Object.keys(mainForm).map(key => {
            if (key === 'upload_photo') {
                if (this.tmpPhoto.name) {
                    formData.append(key, this.tmpPhoto)
                } else {
                    formData.append(key, mainForm[key])
                }
            } else {
                formData.append(key, mainForm[key])
            }
        })

        let resultPlotInfo = null
        let result = null
        this.plotInfo.general_info_id = this.generalId
        this.plotInfo.b_latitude = this.center[0]
        this.plotInfo.b_longitude = this.center[1]
        resultPlotInfo = await RestApi.postData(agriResearchServiceBaseUrl, storeFieldSeedInfo, { ...this.plotInfo, certificate_issue_date: this.seed_source.certificate_issue_date })
        if (resultPlotInfo.success) {
        } else {
            this.$toast.error({
            title: 'Error',
            color: '#D6E09B'
            })
            this.$refs.form.setErrors(resultPlotInfo.errors)
        }
        result = await RestApi.postData(agriResearchServiceBaseUrl, storeSourceInfo, formData)
        if (result && result.success) {
            // this.$toast.success({
            //     title: 'Success',
            //     message: this.$t('globalTrans.save_msg'),
            //     color: '#D6E09B'
            // })
        } else {
            this.$toast.error({
            title: 'Error',
            color: '#D6E09B'
            })
            this.$refs.form.setErrors(result.errors)
        }
        this.plot_location.general_info_id = this.generalId
        result = await RestApi.postData(agriResearchServiceBaseUrl, storeSCAPlotDetails, this.plot_location)
        if (result && result.success) {
            this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })
            this.loading = false
            return result
        } else {
            this.$toast.error({
            title: 'Error',
            message: this.$t('globalTrans.form_error_msg'),
            color: '#D6E09B'
            })
            this.$refs.form.setErrors(result.errors)
        }
    },
    getFieldSeedinfo () {
        this.loading = false
    //     if (this.itemData.seed_source) {
    //         this.seedNameList = this.getSeedList(this.seed_source.seed_type_id)
    // //      this.getVarietyList(parseInt(this.seed_source.seed_type_id), parseInt(this.seed_source.seed_name_id))
    //         this.getVarietyListdummy()
    //     }
    //     // this.loading = false
    //     return true
    },
    onPhotoChange (e) {
      this.tmpPhoto = e.target.files[0]
    },
    getSeedList (typeId = 0) {
      const seedNameList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.filter(seed => seed.seed_type_id === parseInt(typeId) && seed.status === 0)
      return seedNameList.map(item => {
          return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
      })
    },
    getVarietyList (nameId) {
        const list = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.filter(seed => seed.seed_name_id === parseInt(nameId) && seed.status === 0)
        return list.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    getVarietyListdummy (typeId = 0, nameId = 0) {
        const list = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.filter(seed => seed.status === 0)
        this.seedVarietyList = list.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    getSeedName (id) {
        if (this.seed_source.seed_name_id) {
            const name = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.find(e => e.value === parseInt(id))
            this.plotInfo.seed_name = name.text_en
        }
    },
    getSeedVariety (id) {
        const variety = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.find(e => e.value === parseInt(id))
        this.plotInfo.seed_variety_name = variety.text_en
    }
  }
}
</script>
<style scoped>
 .form-control {
  padding: 0.375rem 0.75rem
 }
</style>
