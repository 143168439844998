
<template>
    <b-overlay :show="loading">
    <div v-if="sys_check === 2">
            <p><br></p>
            <center><h1><p>{{$t('external_research.ins_two')}}</p></h1>
            </center>
            <p><br></p>
            <p><br></p>
            <p><br></p>
    </div>
    <div v-else-if="sys_check === 3">
            <p><br></p>
            <center><h1><p>{{$t('external_research.ins_three')}}</p></h1>
            </center>
            <p><br></p>
            <p><br></p>
            <p><br></p>
    </div>
    <div v-else-if="sys_check === 5">
            <p><br></p>
            <center><h1><p>{{$t('external_research.certify_approval')}}</p></h1>
            </center>
            <p><br></p>
            <p><br></p>
            <p><br></p>
    </div>
    <div v-else-if="sys_check === 6">
            <p><br></p>
            <center><h1><p>{{$t('external_research.certify_publication')}}</p></h1>
            </center>
            <p><br></p>
            <p><br></p>
            <p><br></p>
    </div>
    <div v-else>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(afterFlowerComesSubmit)" @reset.prevent="reset" >
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.after_flowers_come') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row >
                                    <b-col sm="3">
                                        <ValidationProvider name="Production Year" vid="production_year" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="production_year" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.production_year')}}
                                                </template>
                                                <b-form-input
                                                    v-model="fiscalYear"
                                                    id="fiscal_year"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    readonly
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Zone or Area Name(En)" vid="b_seed_producer_name" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="b_seed_producer_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.zone_or_area_name')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.seed_producer_name_bn"
                                                    id="seed_producer_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    readonly
                                                    v-if="$i18n.locale === 'bn'"
                                                    >
                                                </b-form-input>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.seed_producer_name"
                                                    id="seed_producer_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    readonly
                                                    v-else
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider vid="b_seed_producer_name">
                                            <b-form-group class="row" label-cols-sm="12" label-for="b_seed_producer_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('crop_price_config.Latitude')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.b_latitude"
                                                    id="seed_producer_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    readonly
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider vid="b_longitude">
                                            <b-form-group class="row" label-cols-sm="12" label-for="b_seed_producer_name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('crop_price_config.Longitude')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.b_longitude"
                                                    id="seed_producer_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    readonly
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Block Sub Area(En)" vid="block_sub_area" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="block_sub_area" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.block_sub_area_x')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="plotInfo.block_sub_area_bn"
                                                    id="block_sub_area"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    readonly
                                                    v-if="$i18n.locale === 'bn'"
                                                >
                                                </b-form-input>
                                                 <b-form-input
                                                    plain
                                                    v-model="plotInfo.block_sub_area"
                                                    id="block_sub_area"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    readonly
                                                    v-else
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Type" vid="production_season_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="production_season_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.production_season')}}
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="plotInfo.production_season_id"
                                                    :options="productionSeasonList"
                                                    id="production_season_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Type" vid="seed_type_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_type_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_type')}}
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_type_id"
                                                    :options="seedTypeList"
                                                    id="seed_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Name" vid="seed_name_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_name_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('research_seed.seed_name')}}
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_name_id"
                                                    :options="seedNameList"
                                                    id="seed_name_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Variety" vid="seed_variety_id" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_variety_id" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('external_research.seed_variety')}}
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="seed_source.seed_variety_id"
                                                    :options="seedVarietyList"
                                                    id="seed_variety_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Class" vid="seed_class_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_class_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('external_research.seed_class')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="seed_source.seed_class_id"
                                                :options="seedClassList"
                                                id="seed_class_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Source Name(En)" vid="seed_source_name" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_source_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_source_name_en')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                id="seed_source_name"
                                                v-model="seed_source.seed_source_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Source Name(Bn)" vid="seed_source_name_bn" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_source_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_source_name_bn')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                id="seed_source_name_bn"
                                                v-model="seed_source.seed_source_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Lot Number" vid="seed_lot_no" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_lot_no" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_lot_no')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                type="number"
                                                id="seed_lot_no"
                                                v-model="seed_source.lot_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col sm="3">
                                        <ValidationProvider name="Seed Producer Name(Bn)" vid="seed_producer_name_bn" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_producer_name_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.seed_producer_name')}}
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_producer_name_bn"
                                                    id="seed_producer_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    v-if="$i18n.locale === 'bn'"
                                                >
                                                </b-form-input>
                                                 <b-form-input
                                                    plain
                                                    v-model="seed_source.seed_producer_name"
                                                    id="seed_producer_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    v-else
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Seed Producer Address(En)" vid="seed_producer_address" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_producer_address" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.seed_producer_address')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="seed_source.seed_producer_address_bn"
                                                id="seed_producer_address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-if="$i18n.locale === 'bn'"
                                                disabled
                                            >
                                            </b-form-input>
                                            <b-form-input
                                                plain
                                                v-model="seed_source.seed_producer_address"
                                                id="seed_producer_address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                v-else
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col sm="3">
                                        <ValidationProvider name="Class Of Seeds" vid="class_of_seeds" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="class_of_seeds" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.class_of_seeds')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="seed_source.class_of_seeds"
                                                :options="classes"
                                                id="class_of_seeds"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col sm="3">
                                        <ValidationProvider name="Seed Production Area" vid="seed_production_area" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_production_area" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                {{$t('certify_form.seed_production_area')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    plain
                                                    v-model="data.seed_production_area"
                                                    id="seed_production_area"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Possible Seed Receipt" vid="possible_seed_receipt" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="possible_seed_receipt" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.possible_seed_receipt')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                type="number"
                                                id="possible_seed_receipt"
                                                v-model="data.possible_seed_receipt"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col sm="3">
                                        <ValidationProvider name="Unit Of Measurement" vid="unit_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="unit_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('research_seed.unit_of_measurement')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                id="unit_id"
                                                :options="unitList"
                                                v-model="data.unit_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Possible Date Of Harvesting" vid="possible_date_harvesting" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="possible_date_harvesting" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.possible_date_harvesting')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                class="date-picker"
                                                id="possible_date_harvesting"
                                                v-model="data.possible_date_harvesting"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Haulm Pulling for potato" vid="haulm_pulling_potato_date">
                                        <b-form-group class="row" label-cols-sm="12" label-for="haulm_pulling_potato_date" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{$t('certify_form.haulm_pulling_potato_date')}}
                                            </template>
                                            <b-form-input
                                                plain
                                                class="date-picker"
                                                id="haulm_pulling_potato_date"
                                                v-model="data.haulm_pulling_potato_date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </div>
    </b-overlay>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeFlowersComeInfo } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'
export default {
    props: ['itemData', 'generalId', 'data', 'basicSeed', 'seed_source', 'plotInfo', 'plot_location', 'fiscalYear', 'app_status', 'sys_check'],
    data () {
        return {
            loading: false,
            seedNameList: [],
            seedVarietyList: [],
            year: '',
            classes: [
                {
                    text: 'Breeder',
                    value: 'Breeder'
                },
                {
                    text: 'Foundation',
                    value: 'Foundation'
                },
                {
                    text: 'Certified',
                    value: 'Certified'
                }
            ]
        }
    },
    components: {
        ValidationObserver, ValidationProvider
    },
    computed: {
        unitList: function () {
            const units = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.filter(item => item.status === 0)
            return units.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedTypeList: function () {
            const types = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedTypeNameList.filter(seed => seed.status === 0)
            return types.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedClassList: function () {
            const classes = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedClassNameList
            return classes.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        productionSeasonList: function () {
            const prod = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.productionSeasonList.filter(seed => !seed.status)
            return prod.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
    },
    created () {
        if (this.data.haulm_pulling_potato_date === '0000-00-00') {
            this.data.haulm_pulling_potato_date = ''
        }
        this.data.seed_type_id = this.basicSeed.seed_type_id
        this.data.seed_name_id = this.basicSeed.seed_name_id
        this.data.seed_variety_id = this.basicSeed.seed_variety_id
        this.data.seed_class_id = this.basicSeed.seed_class_id
        if (this.data.seed_type_id) {
            this.seedNameList = this.getSeedList(this.data.seed_type_id)
        }
         if (this.data.seed_name_id) {
            this.seedVarietyList = this.getVarietyList(this.data.seed_name_id)
        }
    },
    watch: {
        'data.seed_type_id': function (newVal, oldVal) {
            this.seedNameList = this.getSeedList(newVal)
        },
        'data.seed_name_id': function (newVal, oldVal) {
            this.seedVarietyList = this.getVarietyList(newVal)
        },
        'data.seed_variety_id': function (newVal, oldVal) {
            this.getSeedVariety(newVal)
        },
        'data.seed_class_id': function (newVal, oldVal) {
            this.getSeedClass(newVal)
        }
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
        getSeedList (typeId = 0) {
            const seedNameList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.filter(seed => seed.seed_type_id === parseInt(typeId))
            return seedNameList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        getVarietyList (nameId) {
            const list = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.filter(seed => seed.seed_name_id === parseInt(nameId))
            return list.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        async afterFlowerComesSubmit (isNext = 1) {
            this.loading = true
            if (!isNext) {
                this.data.draft = isNext
            }
            this.data.general_info_id = this.generalId
            let result = null
            result = await RestApi.postData(agriResearchServiceBaseUrl, storeFlowersComeInfo, this.data)
            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.loading = false
                if (result.data.application_status === 1) {
                    return result
                //    window.vm.$swal({
                //         title: 'Successfull',
                //         showCancelButton: true,
                //         confirmButtonText: window.vm.$t('globalTrans.yes'),
                //         cancelButtonText: window.vm.$t('globalTrans.no'),
                //         focusConfirm: false
                //     }).then((result) => {
                //         if (result.isConfirmed) {
                //             window.location.href = '/research-farmer/seed-certification/field-application-list'
                //         }
                //     })
               } else {
                    return result
               }
            } else {
                this.$toast.error({
                title: 'Error',
                message: this.$t('globalTrans.form_error_msg'),
                color: '#D6E09B'
                })
                this.$refs.form.setErrors(result.errors)
            }
            this.loading = false
        },
        onTreasuryChange (e) {
            this.tmpTreasuryFile = e.target.files[0]
        }
    }
}
</script>

<style>

</style>
