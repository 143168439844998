<template>
    <b-overlay :show="loading">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form  @submit.prevent="handleSubmit(generalSubmit)">
                <b-row>
                    <b-col sm="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <div class="text-center">
                                    <h5 class="card-title">{{ $t('certify_form.doc_attach') }}</h5>
                                </div>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col sm="6">
                                        <ValidationProvider name="Dealer Registration Certificate" vid="dealer_reg_certificate" ref="dealer_reg_certificate" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="dealer_reg_certificate" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.dealer_reg_certificate') }}<span class="text-danger">*</span>
                                            </template>
                                            <div class="d-flex justify-content-between">
                                                <b-form-file
                                                v-model="document.dealer_reg_certificate"
                                                id="dealer_reg_certificate"
                                                @change="onRegCertificateChange"
                                                :placeholder="previewDoc.dealer_reg_certificate ? previewDoc.dealer_reg_certificate : 'Choose a file...'"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-file>
                                                <a v-if="previewDoc.dealer_reg_certificate" :class="`${previewDoc.dealer_reg_certificate} ? '' : 'disabled'`" :href="agriResearchServiceBaseUrl +'storage/uploads/research_farmers/'+ previewDoc.dealer_reg_certificate" target="_blank" title="Download file" class="download-btn">
                                                    <i class="ri-download-cloud-line"></i>
                                                </a>
                                            </div>
                                            <div v-if="!errors[0]" class="invalid-feedback d-block text-dark">
                                            {{ 'Dealer registration certificate must be a file of type: pdf, xls, xlsx, doc, docx, csv.' }}
                                            </div>
                                            <div v-else class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="6">
                                        <ValidationProvider name="Mouja Map" vid="mouja_map" ref="mouja_map" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="mouja_map" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.mouja_map') }}<span class="text-danger">*</span>
                                            </template>
                                            <div class="d-flex justify-content-between">
                                                <b-form-file
                                                v-model="document.mouja_map"
                                                id="mouja_map"
                                                @change="onMapChange"
                                                :placeholder="previewDoc.mouja_map ? previewDoc.mouja_map : 'Choose a file...'"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-file>
                                                <a v-if="previewDoc.mouja_map" :class="`${previewDoc.mouja_map} ? '' : 'disabled'`" :href="agriResearchServiceBaseUrl +'storage/uploads/research_farmers/'+ previewDoc.mouja_map" target="_blank" title="Download file" class="download-btn">
                                                    <i class="ri-download-cloud-line" ></i>
                                                </a>
                                            </div>
                                            <div v-if="errors[0]" class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                            </div>
                                            <div v-else class="invalid-feedback d-block text-dark">
                                            {{ 'Mouja map must be a file of type: pdf, xls, xlsx, doc, docx, csv.' }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="6">
                                        <ValidationProvider name="Seed Purchase Receipt" vid="seed_purchase_receipt" ref="seed_purchase_receipt" rules="required">
                                        <b-form-group class="row" label-cols-sm="12" label-for="seed_purchase_receipt" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{ $t('certify_form.seed_purchase_receipt') }}<span class="text-danger">*</span>
                                            </template>
                                            <div class="d-flex justify-content-between">
                                                <b-form-file
                                                    v-model="document.seed_purchase_receipt"
                                                    id="seed_purchase_receipt"
                                                    @change="onReceiptChange"
                                                    :placeholder="previewDoc.seed_purchase_receipt ? previewDoc.seed_purchase_receipt : 'Choose a file...'"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-file>
                                                <a v-if="previewDoc.seed_purchase_receipt" :class="`${previewDoc.seed_purchase_receipt} ? '' : 'disabled'`" :href="agriResearchServiceBaseUrl +'storage/uploads/research_farmers/'+ previewDoc.seed_purchase_receipt" target="_blank" title="Download file" class="download-btn">
                                                    <i class="ri-download-cloud-line" ></i>
                                                </a>
                                            </div>
                                            <div v-if="errors[0]" class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                            </div>
                                            <div v-else class="invalid-feedback d-block text-dark">
                                            {{ 'Seed purchase receipt must be a file of type: pdf, xls, xlsx, doc, docx, csv.' }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="6">
                                        <ValidationProvider name="Source Tag/ Document" vid="source_tag_doc" ref="source_tag_doc1" rules="required">
                                            <b-form-group class="row" label-cols-sm="12" label-for="source_tag_doc" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('certify_form.source_tag_doc') }}<span class="text-danger">*</span>
                                                </template>
                                                <div class="d-flex justify-content-between">
                                                    <b-form-file
                                                        v-model="document.source_tag_doc"
                                                        id="source_tag_doc"
                                                        @change="onTagDocChange"
                                                        :placeholder="previewDoc.source_tag_doc ? previewDoc.source_tag_doc : 'Choose a file...'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-file>
                                                    <a v-if="previewDoc.source_tag_doc" :class="`${previewDoc.source_tag_doc} ? ' : 'disabled'`" :href="agriResearchServiceBaseUrl +'storage/uploads/research_farmers/'+ previewDoc.source_tag_doc" target="_blank" title="Download file" class="download-btn">
                                                        <i class="ri-download-cloud-line" ></i>
                                                    </a>
                                                </div>
                                                <div v-if="errors[0]" class="invalid-feedback d-block">
                                                    {{ errors[0] }}
                                                </div>
                                                <div v-else class="invalid-feedback d-block text-dark">
                                                {{ 'Source Tag/ Document must be a file of type: pdf, xls, xlsx, doc, docx, csv.' }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row v-for="(item, index) in document_details" :key="index">
                                    <b-col sm="3">
                                        <ValidationProvider name="Document name" :vid="'document_name'+index">
                                            <b-form-group class="row" label-cols-sm="12" :label-for="'document_name'+index" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('certify_form.document_name')}}
                                                </template>
                                                <b-form-input
                                                    v-model="item.document_name"
                                                    :id="'document_name'+index"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <ValidationProvider name="Attachment" :vid="'document_id'+index" :ref="'document_id'+index">
                                            <b-form-group class="row" label-cols-sm="12" :label-for="'document_id'+index" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.attachment') }}
                                                </template>
                                                <div class="d-flex justify-content-between">
                                                    <b-form-file
                                                        v-model="item.attachment"
                                                        :id="'document_id'+index"
                                                        @change="changeDocument(index, $event)"
                                                        :placeholder="item.attachment ? item.attachment : 'Choose a file...'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-file>
                                                    <a v-if="item.attachment.lenght > -1" :class="`${item.attachment.lenght > -1} ? '' : 'disabled'`" :href="agriResearchServiceBaseUrl + 'storage/uploads/research_farmers/' + item.attachment" target="_blank" title="Download file" class="download-btn">
                                                        <i class="ri-download-cloud-line" ></i>
                                                    </a>
                                                </div>
                                                <div v-if="errors[0]" class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                                </div>
                                                <div v-else class="invalid-feedback d-block text-dark">
                                                    {{ 'Attachment must be a file of type: pdf, xls, xlsx, doc, docx, csv.' }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="3">
                                        <div style="margin-top: 38px">
                                            <b-badge title="Add Teacher" class="badge badge-success badge-btn mr-2" @click.prevent="addDocument(index)"><i class="ri-add-line m-0"></i></b-badge>
                                            <b-badge v-if="document_details.length-1 === index && index > 0" title="Remove Document" class="badge badge-danger badge-btn" @click="removeDocument(index)"><i class="ri-delete-bin-line m-0"></i></b-badge>
                                        </div>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <embed
                v-if="src"
                :src="src"
                width="100%"
                height="500">
        </p>
        </b-modal>
    </b-overlay>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeFileInfo, getApplicationData } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: ['document', 'generalId'],
  data () {
    return {
        agriResearchServiceBaseUrl,
        formTitle: 'Preview',
        loading: false,
        showModal: false,
        src: '',
        p: 0,
        errors: [],
        document_details: [
            {
                document_name: '',
                attachment: [],
                file: ''
            }
        ],
        previewDoc: {
            general_info_id: '',
            dealer_reg_certificate: '',
            mouja_map: '',
            seed_purchase_receipt: '',
            source_tag_doc: ''
        },
        mainDoc: {
            general_info_id: '',
            dealer_reg_certificate: '',
            mouja_map: '',
            seed_purchase_receipt: '',
            source_tag_doc: ''
        }
    }
  },
  created () {
    // this.data.general_info_id = this.generalId
    this.prevDoc()
    this.mainDoc.dealer_reg_certificate_check = this.document.dealer_reg_certificate
    this.mainDoc.mouja_map_check = this.document.mouja_map
    this.mainDoc.seed_purchase_receipt_check = this.document.seed_purchase_receipt
    this.mainDoc.source_tag_doc_check = this.document.source_tag_doc
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  computed: {
    districtList: function () {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      return districtList.map(item => {
        return Object.assign({}, item, { value: item.value, text: item.text })
      })
    },
    fiscalYearList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
    }
  },
  methods: {
    prevDoc () {
            RestApi.getData(agriResearchServiceBaseUrl, getApplicationData + '/' + this.generalId).then(response => {
                if (response.success) {
                    this.previewDoc.dealer_reg_certificate = response.data.document.dealer_reg_certificate
                    this.previewDoc.mouja_map = response.data.document.dealer_reg_certificate
                    this.previewDoc.seed_purchase_receipt = response.data.document.dealer_reg_certificate
                    this.previewDoc.source_tag_doc = response.data.document.dealer_reg_certificate
                } else {
                }
            })
    },
    addDocument () {
        this.document_details.push({
            document_name: '',
            attachment: [],
            file: ''
        })
    },
    removeDocument (index) {
        this.document_details.splice(index, 1)
    },
    showPreview (type = '') {
        if (type === 'mouja_map' && this.tmpMap.name) {
            this.$bvModal.show('modal-4')
            this.src = URL.createObjectURL(this.tmpMap)
        } else if (type === 'seed_purchase_receipt' && this.tmpReceipt.name) {
            this.$bvModal.show('modal-4')
            this.src = URL.createObjectURL(this.tmpReceipt)
        } else if (type === 'source_tag_doc' && this.tmpTagDoc.name) {
            this.$bvModal.show('modal-4')
            this.src = URL.createObjectURL(this.tmpTagDoc)
        } else if (type === 'dealer_reg_certificate' && this.tmpCertificate.name) {
            this.$bvModal.show('modal-4')
            this.src = URL.createObjectURL(this.tmpCertificate)
        }
    },
    async getDocFile () {
        this.loading = false
        if (this.itemData.id) {
            this.data = { ...this.itemData, ...this.itemData.document, table_id: this.itemData.id }
        }
        this.loading = false
    },
    async docSubmit (isNext = 1) {
        // Front-End Validation
        const validationResult = await this.$refs.form.validate()
        if (!validationResult) return false

      this.loading = true
      if (!isNext) {
          this.document.draft = isNext
      }
      this.mainDoc.general_info_id = this.generalId
      let result = null
      result = await RestApi.postData(agriResearchServiceBaseUrl, storeFileInfo, { ...this.mainDoc, document_details: this.document_details })
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.loading = false
          this.prevDoc()
        return result
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.prevDoc()
      }
      this.loading = false
    },
    async onRegCertificateChange (e) {
        const { valid } = await this.$refs.dealer_reg_certificate.validate(e)
        if (!valid) return false
        const input = e.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.mainDoc.dealer_reg_certificate = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.mainDoc.dealer_reg_certificate = ''
        }
    },
    async onMapChange (e) {
        const { valid } = await this.$refs.mouja_map.validate(e)
        if (!valid) return false
        const input = e.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.mainDoc.mouja_map = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.mainDoc.mouja_map = ''
        }
    },
    async onReceiptChange (e) {
        const { valid } = await this.$refs.seed_purchase_receipt.validate(e)
        if (!valid) return false
        const input = e.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.mainDoc.seed_purchase_receipt = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.mainDoc.seed_purchase_receipt = ''
        }
    },
    async onTagDocChange (e) {
        const { valid } = await this.$refs.source_tag_doc1.validate(e)
        if (!valid) return false
        const input = e.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.mainDoc.source_tag_doc = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.mainDoc.source_tag_doc = ''
        }
    },
    changeDocument (index, e) {
        const input = e.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.document_details[index].file = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.document_details[index].file = ''
        }
    }
  }
}
</script>
<style scoped>
    .form-control {
        padding: 0.375rem 0.75rem
    }
    .badge-btn{
        margin-top: 5px !important;
        height: 22px !important;
        cursor: pointer;
    }
    .download-btn{
        font-size: 20px;
        margin-top: -3px;
        margin-left: 5px;
    }
</style>
